import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBContainer,
} from "mdbreact";
import "./About.css";
import Drawer from "./../../components/drawer/drawer";
import Navbar from "./../../components/navbar/navbar";
import Button from "@material-ui/core/Button";
import Cards from "./../../components/Cards/Cards";

import Footer from "./../../components/Footer/Footer";

class About extends React.Component {
  render() {
    return (
      <div>
        <div className="_menuAppBar">
          <Navbar props={this.props.history} />
        </div>
        <div className="_drawer">
          <Drawer props={this.props} />
        </div>
        <h1 className="text">About Team Memberes</h1>
        <MDBRow
          style={{
            marginTop: 80,
            alignSelf: "center",
            alignContent: "center",
            alignItems: "center",
            margin: "auto",
          
          }}
        >
          <Cards name="KAMAL UD DIN " />
          <Cards name="HEERJI SODHA " />
          <Cards name="KAILASH KUMAR" />
        </MDBRow>

        <div style={{ float: "left", width: "100%" }}>
          <Footer />
        </div>
      </div>
    );
  }
}

export default About;
