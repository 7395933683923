import React from "react";
import "./shareWith.css";
import MenuAppBar from "../../components/navbar/navbar";
import Drawer from "./../../components/drawer/drawer";
import { MDBModalBody, MDBModalHeader } from "mdbreact";
import Paper from "@material-ui/core/Paper";

import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";

export default class ShareWith extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      papers: [],
      selected: 0,
      modal2: false,
    };
  }

  render() {
    let selectedQuiz = this.props.location.state.selected;
    return (
      <div>
        {/* -----------------------------> NAVBAR <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>

        <div className="_drawer">
          <Drawer props={this.props} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <MDBModalHeader>Share Quiz</MDBModalHeader>
        <MDBModalBody className="_share_container">
          <Paper className="_data_card">
            <p style={{textAlign:"center",fontSize:15}}>Share {selectedQuiz.subject} Quiz with friends </p>
            <p>Quiz url:{`https://www.ntsquiz.com/${selectedQuiz.quizId}`}</p>
            <p>Subject:{selectedQuiz.subject}</p>
            <p>Quiz Name:{selectedQuiz.title}</p>
            <p>Total Question:{selectedQuiz.questions}</p>
            <p>Passing Score:{selectedQuiz.passingScore}</p>
            <p style={{textAlign:"center"}}>Share with</p>


          <p style={{ textAlign: "center",display:"flex",flexDirection:"row",justifyContent:"center" }}>
            <div className="_shareView">
            <FacebookShareButton className="m-2"
             url={`https://www.ntsquiz.com/${selectedQuiz.quizId}`}
             quote={ selectedQuiz.subject !== undefined
              ? `Subject:${selectedQuiz.subject} \nQuiz Name: ${selectedQuiz.title} \nTotal Question:${selectedQuiz.questions} \nPassing Score:${selectedQuiz.passingScore} \nLets start 👇\n`
              : ""}
             hashtag="#ntsquiz #education"
            
             >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
              </div>

              <div className="_shareView">
            <WhatsappShareButton
              className="m-2"
              title={
                selectedQuiz.subject !== undefined
                ? `Subject:${selectedQuiz.subject} \nQuiz Name: ${selectedQuiz.title} \nTotal Question:${selectedQuiz.questions} \nPassing Score:${selectedQuiz.passingScore} \nLets start 👇\n`
                : ""
              }
              url={
                selectedQuiz.quizId !== undefined
                ? `https://www.ntsquiz.com/${selectedQuiz.quizId}`
                : ""
              }
              >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
              </div>
            {/* <EmailShareButton className="m-2">
              <EmailIcon size={32} round={true} />
            </EmailShareButton> */}
          </p>
          </Paper>
        </MDBModalBody>
      </div>
    );
  }
}
