import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  //   MDBTestimonial,
  MDBIcon,
} from "mdbreact";

const TestimonialsPage = () => {
  return (
    <MDBContainer>
      <section className="team-section text-center my-5">
        <h2 className="h1-responsive font-weight-bold my-5">
        What other say for us
        </h2>
        <p className="dark-grey-text w-responsive mx-auto mb-5">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
          amet numquam iure provident voluptate esse quasi, veritatis totam
          voluptas nostrum quisquam eum porro a pariatur veniam.
        </p>

        <MDBRow className="text-center">
          <MDBCol md="4" className="mb-md-0 mb-5">
            <img
              src="https://mdbootstrap.com/img/Photos/Avatars/img%20(1).jpg"
              alt=""
              className="rounded-circle z-depth-1 img-fluid"
              style={{ height: 80, width: 80 }}
            />
            <h4 className="font-weight-bold dark-grey-text mt-4">
              Anna Deynah
            </h4>
            <h6 className="font-weight-bold blue-text my-3">Web Designer</h6>
            <p className="font-weight-normal dark-grey-text">
              <MDBIcon className="fa fa-quote-left pr-2" />
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos
              id officiis hic tenetur quae quaerat ad velit ab hic tenetur.
            </p>
            <div className="orange-text">
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
            </div>
            {/* </MDBTestimonial> */}
          </MDBCol>

          <MDBCol md="4" className="mb-md-0 mb-5">
            <img
              src="https://mdbootstrap.com/img/Photos/Avatars/img%20(1).jpg"
              alt=""
              className="rounded-circle z-depth-1 img-fluid"
              style={{ height: 80, width: 80 }}
            />
            <h4 className="font-weight-bold dark-grey-text mt-4">
              Anna Deynah
            </h4>
            <h6 className="font-weight-bold blue-text my-3">Web Designer</h6>
            <p className="font-weight-normal dark-grey-text">
              <MDBIcon className="fa fa-quote-left pr-2" />
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos
              id officiis hic tenetur quae quaerat ad velit ab hic tenetur.
            </p>
            <div className="orange-text">
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
            </div>
            {/* </MDBTestimonial> */}
          </MDBCol>

          <MDBCol md="4" className="mb-md-0 mb-5">
            <img
              src="https://mdbootstrap.com/img/Photos/Avatars/img%20(1).jpg"
              alt=""
              className="rounded-circle z-depth-1 img-fluid"
              style={{ height: 80, width: 80 }}
            />
            <h4 className="font-weight-bold dark-grey-text mt-4">
              Anna Deynah
            </h4>
            <h6 className="font-weight-bold blue-text my-3">Web Designer</h6>
            <p className="font-weight-normal dark-grey-text">
              <MDBIcon className="fa fa-quote-left pr-2" />
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos
              id officiis hic tenetur quae quaerat ad velit ab hic tenetur.
            </p>
            <div className="orange-text">
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
              <MDBIcon icon="star" />
            </div>
            {/* </MDBTestimonial> */}
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default TestimonialsPage;
