import React from "react";
import Drawer from "./../../components/drawer/drawer";
import Navbar from "./../../components/navbar/navbar";
import { firebaseApp } from "./../../config/Firebase";
import Paper from "@material-ui/core/Paper";
import "./Result.css";
import Divider from "@material-ui/core/Divider";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

let database = firebaseApp.database().ref("/");
export default class Result extends React.Component {
  constructor() {
    super();
    this.state = {
      newQuiz: [],
      completeArr: [],
      currentuser: {},
    };
  }

  async componentDidMount() {
    firebaseApp
      .firestore()
      .collection("result")
      .where("Marks", "<", 100)
      .orderBy("Marks", "desc")
      .limit(4)
      .get()
      .then((querySnapshot) => {
        // Document was found in the cache. If no cached document exists,
        // an error will be returned to the 'catch' block below.
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting cached document:", error);
      });

    // await firebaseApp.auth().onAuthStateChanged(async (user) => {
    //   if (user) {
    //     await database.child("Users/" + user.uid + "/").once("value", (res) => {
    //       let data = res.val();
    //       this.setState({ currentuser: data });
    //       localStorage.setItem("activeuser", JSON.stringify(data));
    //     });
    //     database
    //       .child("Users/" + this.state.currentuser.uid + "/" + "CompletedQuiz")
    //       .once("value", (snapshot) => {
    //         let completeArr = [];
    //         if (snapshot.val()) {
    //           database
    //             .child(
    //               "Users/" + this.state.currentuser.uid + "/" + "CompletedQuiz"
    //             )
    //             .on("child_added", (res) => {
    //               let v = res.val();
    //               v.quizId = res.ref.path.pieces_[1];
    //               completeArr.push(v);
    //               this.setState({ completeArr: completeArr });
    //             });
    //         }
    //       });
    //   } else {
    //     console.log("No user is signed in.");
    //     this.props.history.push("Login");
    //   }
    // });
  }
  render() {
    let { result } = this.props.location.state;

    console.log(result);
    let { currentuser, completeArr } = this.state;
    return (
      <div className="container">
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_menuAppBar">
          <Navbar props={this.props.history} />
        </div>
        <div className="_drawer">
          <Drawer data={currentuser} props={this.props} />
        </div>
        <div className="_inner_view">
          <div
            style={{
              width: "25%",
              height: "25%",
              margin: "auto",
              marginBottom: 40,
            }}
          >
            <CircularProgressbar
              value={result.Right * 2}
              text={`${result.Right * 2}%`}
            />
          </div>
          <Paper>
            <div className="main_row">
              <span className="topic_Row">Subject</span>
              <span className="topic_Row">Total Questions</span>
              <span className="topic_Row">Correct</span>
              <span className="topic_Row">Wrong</span>
              <span className="topic_Row">Score</span>
            </div>
            <Divider />
            {result.ResultArray.length !== 0 ? (
              result.ResultArray.map((val, i) => {
                return (
                  <>
                    <div className="main_row">
                      <div>{val.Name}</div>
                      <div>{val.Right + val.Wrong}</div>
                      <div>{val.Right}</div>
                      <div>{val.Wrong}</div>
                      <div>{(val.Right * 2).toFixed(2)}</div>
                    </div>
                    <Divider />
                  </>
                );
              })
            ) : (
              <h5 style={{ padding: 10 }}>you did not attempted any test</h5>
            )}
          </Paper>
        </div>
      </div>
    );
  }
}
