import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
} from "mdbreact";
import Drawer from "./../../components/drawer/drawer";
import "./home.css";
import { firebaseApp } from "./../../config/Firebase";
import MenuAppBar from "./../../components/navbar/navbar";
import newLogo from "./../../img/logo icon 2-01.png";
import FooterPage from "./../../components/Footer/Footer";
import { MDBBtn, MDBFormInline } from "mdbreact";
import "react-multi-carousel/lib/styles.css";
import bg from "./../../img/pstjstpreparation.png";
import ntswinner from "./../../img/ntswinner.png";
import Testimonal from "./../../components/testimonal";
import QuestionImage from "./../../img/14fd8efea17748c4b959a07f91e09154.png";
import { CSSTransitionGroup } from "react-transition-group"; // ES6

import SubjectImage from "./../../img/two.jpg";
import { Perpare } from "../../config/Objects/Perparation";

let database = firebaseApp.database().ref("/");
export default class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      newQuiz: [],
      completeArr: [],
      currentuser: {},
      quizArr: [],
    };
  }
  componentDidMount() {
    console.log(Perpare);
    let arr = [];
    console.log(arr);
    firebaseApp
      .firestore()
      .collection("Tests")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());

          arr.push(doc.data());
        });
      })
      .then(() => this.setState({ quizArr: arr }))
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

      console.log(this.state.quizArr,"==========")
    // database.child("Categories/").on("child_added", async (res) => {
    //   let data = res.val();
    //   data.quizId = res.key;
    //   let subArr = [];
    //   await database
    //     .child("Categories/" + res.key + "/" + "cat/")
    //     .on("child_added", (a) => {
    //       let d = a.val();
    //       d.subjectId = a.key;
    //       subArr.push(d);
    //     });

    //   data.cat = subArr;
    //   arr.push(data);
    //   this.setState({ quizArr: arr });
    // });
  }

  render() {
    let { currentuser, quizArr } = this.state;
    console.log("Quiz===============> ", quizArr);
    return (
      <div className="_main">
        {/* -----------------------------> NAVBAR <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_intro_section align-items-center">
          <MDBContainer>
            <MDBRow className="d-flex align-items-center justify-content-center">
              <MDBCol lg="6" xl="6" md="12" sm="12">
                <span className="_Welcome_Title">
                  Welcome to Our <br />
                  Online quiz Service
                </span>
                <p className="_Welcome_Description">
                  online tests are a useful source of self examination which can
                  evaluate your level of preparations and IQ. online tests are a
                  useful source of self examination which can evaluate your
                  level of preparations and IQ.
                </p>
                <div className="_StartQuiz">
                  <MDBBtn
                    className="_ButtonSelf"
                    color="none"
                    onClick={() => this.props.history.push("/availablequiz")}
                  >
                    Start Quiz
                  </MDBBtn>
                </div>
              </MDBCol>
              <MDBCol lg="6" xl="6" md="12" sm="12" style={{ paddingTop: 50 }}>
                <img src={QuestionImage} width="80%" height={"80%"} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="_drawer">
          <Drawer data={currentuser} props={this.props} />
        </div>
        <div className="_studentPosition">
          <MDBContainer>
            <section className="text-center my-5">
              <h2 className="h1-responsive font-weight-bold my-5">
                Subeject Questions
              </h2>
              <p className="dark-grey-text w-responsive mx-auto mb-5">
                You can perpare here for Subject
              </p>
              <MDBRow>
                {Perpare.length > 0 &&
                  Perpare.map((val, ind) => (
                    <MDBCol lg="2" md="3" sm="6" xs="12" className="mb-lg-0 mb-4">
                      <MDBCard testimonial>
                        {/* <MDBCardUp color="info" /> */}
                        {/* <MDBAvatar className="mx-auto white"> */}
                        <div className="_card_header">
                          <img className="_subjectImage" src={SubjectImage} />
                        </div>

                        {/* </MDBAvatar> */}
                        <MDBCardBody>
                          <div className="_card_title">
                            <h6 className="font-weight-bold mb-4">Physics</h6>
                            <div className="_position_title">Perpare</div>
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  ))}
              </MDBRow>
            </section>
          </MDBContainer>
        </div>
        <div className="_studentPosition">
          <MDBContainer>
            <section className="text-center my-5">
              <h2 className="h1-responsive font-weight-bold my-5">
                Positon holder students
              </h2>
              <p className="dark-grey-text w-responsive mx-auto mb-5">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi,
                veritatis totam voluptas nostrum quisquam eum porro a pariatur
                veniam.
              </p>
              <MDBRow>
                <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                  <MDBCard testimonial>
                    {/* <MDBCardUp color="info" /> */}
                    {/* <MDBAvatar className="mx-auto white"> */}
                    <div className="_card_header"></div>
                    <img
                      src="https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg"
                      alt=""
                      className="rounded-circle img-fluid"
                    />
                    {/* </MDBAvatar> */}
                    <MDBCardBody>
                      <div className="_card_title">
                        <h6 className="font-weight-bold mb-4">John Doe</h6>
                        <div className="_position_title">1st Position</div>
                      </div>
                      <hr />
                      <div className="_card_title">
                        <div className="dark-grey-text">Score</div>{" "}
                        <div className="dark-grey-tex">98%</div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                  <MDBCard testimonial>
                    {/* <MDBCardUp gradient="blue" /> */}
                    {/* <MDBAvatar className="mx-auto white"> */}
                    <div className="_card_header"></div>

                    <img
                      src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg"
                      alt=""
                      className="rounded-circle img-fluid"
                    />
                    {/* </MDBAvatar> */}
                    <MDBCardBody>
                      <div className="_card_title">
                        <h6 className="font-weight-bold mb-4">John Doe</h6>
                        <div className="_position_title">2nd Position</div>
                      </div>
                      <hr />
                      <div className="_card_title">
                        <div className="dark-grey-text">Score</div>{" "}
                        <div className="dark-grey-tex">96%</div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                  <MDBCard testimonial>
                    {/* <MDBCardUp className="indigo" /> */}
                    {/* <MDBAvatar className="mx-auto white"> */}
                    <div className="_card_header"></div>

                    <img
                      src="https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg"
                      alt=""
                      className="rounded-circle img-fluid"
                    />
                    {/* </MDBAvatar> */}
                    <MDBCardBody>
                      <div className="_card_title">
                        <h6 className="font-weight-bold mb-4">John Doe</h6>
                        <div className="_position_title">3rd Position</div>
                      </div>
                      <hr />
                      <div className="_card_title">
                        <div className="dark-grey-text">Score</div>{" "}
                        <div className="dark-grey-tex">90.6%</div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>

                <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                  <MDBCard testimonial>
                    {/* <MDBCardUp className="indigo" /> */}
                    {/* <MDBAvatar className="mx-auto white"> */}
                    <div className="_card_header"></div>

                    <img
                      src="https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg"
                      alt=""
                      className="rounded-circle img-fluid"
                    />
                    {/* </MDBAvatar> */}
                    <MDBCardBody>
                      <div className="_card_title">
                        <h6 className="font-weight-bold mb-4">John Doe</h6>
                        <div className="_position_title">4rth Position</div>
                      </div>
                      <hr />
                      <div className="_card_title">
                        <div className="dark-grey-text">Score</div>{" "}
                        <div className="dark-grey-tex">80%</div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </section>
          </MDBContainer>
        </div>
        <br /> <br />
        {/* COUNTER OF THE STUDENTS */}
        <div className="_student_counter_row">
          <MDBContainer>
            <MDBRow center>
              <MDBCol size="4">
                <div className="_counter">
                  <span className="_title">Registered users</span>
                  <span className="_value">9500+</span>
                </div>
              </MDBCol>
              <MDBCol size="4">
                <div className="_counter">
                  <span className="_title">Total Questions</span>
                  <span className="_value">5090+</span>
                </div>
              </MDBCol>
              <MDBCol size="4">
                <div className="_counter">
                  <span className="_title">Total Questions</span>
                  <span className="_value">5090+</span>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <br /> <br /> <br /> <br />
        <Testimonal />
        <br /> <br /> <br /> <br />
        <FooterPage />
      </div>
    );
  }
}
