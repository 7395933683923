import React from "react";
import Drawer from "./../../components/drawer/drawer";
import { firebaseApp } from "./../../config/Firebase";
import Paper from "@material-ui/core/Paper";
import "./Profile.css";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuAppBar from "./../../components/navbar/navbar";
import { MDBBtn, MDBIcon } from "mdbreact";

let database = firebaseApp.database().ref("/");
export default class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      newQuiz: [],
      completeArr: [],
      currentuser: {},
    };
  }

  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        database.child("users/" + user.uid + "/").once("value", (res) => {
          let data = res.val();
          this.setState({ currentuser: data });
        });
      } else {
        this.props.history.push("login");
      }
    });
  }

  signout = () => {
    firebaseApp
      .auth()
      .signOut()
      .then(
        function () {
          this.props.history.push("/");
          console.log("Signed Out");
        },
        function (error) {
          console.error("Sign Out Error", error);
        }
      );
  };

  render() {
    let { currentuser } = this.state;
    return (
      <div>
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_drawer">
          <Drawer data={currentuser} props={this.props} />
        </div>

        <div className="_inner_view">
          <Grid container spacing={2} justify="center">
            <Grid item xs={11} lg={6}>
              <Paper style={{ padding: 10 }}>
                <p style={{ textAlign: "center" }}>
                  <img
                    src={currentuser.photo}
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: 50,
                      verticalAlign: "middle",
                      marginTop: 30,
                    }}
                  />
                </p>
                <TextField
                  required
                  id="standard-required"
                  label="Name"
                  defaultValue="Name"
                  className="_text_field"
                  disabled
                  value={currentuser.user}
                />

                <TextField
                  required
                  id="standard-required"
                  label="Email"
                  defaultValue="Email"
                  className="_text_field"
                  disabled
                  value={currentuser.email}
                />

                <p style={{ textAlign: "center" }}>
                  <MDBBtn gradient="peach" onClick={() => this.signout()}>
                    <MDBIcon icon="sign-out-alt" /> Logout
                  </MDBBtn>
                </p>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
