// import React from "react";
// import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

// const FooterPage = () => {
//   return (
//     <MDBFooter color="blue" className="font-small pt-4 mt-4">
//       <MDBContainer fluid className="text-center text-md-left">
//         <MDBRow>
//           <MDBCol md="6">
//             <h5 className="title">Footer Content</h5>
//             <p>
//               Here you can use rows and columns here to organize your footer
//               content.
//             </p>
//           </MDBCol>
//           <MDBCol md="6">
//             <h5 className="title">Links</h5>
//             <ul>
//               <li className="list-unstyled">
//                 <a href="#!">Link 1</a>
//               </li>
//               <li className="list-unstyled">
//                 <a href="#!">Link 2</a>
//               </li>
//               <li className="list-unstyled">
//                 <a href="#!">Link 3</a>
//               </li>
//               <li className="list-unstyled">
//                 <a href="#!">Link 4</a>
//               </li>
//             </ul>
//           </MDBCol>
//         </MDBRow>
//       </MDBContainer>
//       <div className="footer-copyright text-center py-3">
//         <MDBContainer fluid>
//           &copy; {new Date().getFullYear()} Copyright:{" "}
//           <a href="https://www.mdbootstrap.com"> MDBootstrap.com </a>
//         </MDBContainer>
//       </div>
//     </MDBFooter>
//   );
// };

// export default FooterPage;

import React from "react";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
  AiFillInstagram,
  AiFillMail,
} from "react-icons/ai";
import FacebookIcon from "@mui/icons-material/Facebook";
import { BiLocationPlus, BiPhone } from "react-icons/bi";
import "./footer.css";
import { Container, Grid } from "@mui/material";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="_footerDiv">
        <Container>
          <Grid container justify="center" spacing={3}>
            <Grid item lg={3} xs={10}>
              <div>
                <p className="_siteTitle">MENTORING</p>
                <p className="_siteDes">
                  Are you looking to join selline intstitutions? Now it's very
                  simple, Signup with mentoring.Are you looking to join selline
                  intstitutions? Now it's very simple, Signup with mentoring.
                </p>
                <div className="_socialIconDiv">
                  <FacebookIcon size="24" className="_socialIcon" />
                  <AiFillTwitterSquare size="24" className="_socialIcon" />
                  <AiFillLinkedin size="24" className="_socialIcon" />
                  <AiFillInstagram size="24" className="_socialIcon" />
                </div>
              </div>
            </Grid>
            <Grid item lg={3} xs={10}>
              <div>
                <p className="_pagesHeading">For Mentores</p>
                <p className="_pageName">Search Mentore</p>
                <p className="_pageName">Login</p>
                <p className="_pageName">Register</p>
                <p className="_pageName">Booking</p>
                <p className="_pageName">Mentore Dashboard</p>
              </div>
            </Grid>
            <Grid item lg={3} xs={10}>
              <div>
                <p className="_pagesHeading">For Mentores</p>
                <p className="_pageName">Search Mentore</p>
                <p className="_pageName">Login</p>
                <p className="_pageName">Register</p>
                <p className="_pageName">Booking</p>
                <p className="_pageName">Mentore Dashboard</p>
              </div>
            </Grid>
            <Grid item lg={3} xs={10}>
              <div className="_footerContactDiv">
                <p className="_pagesHeading">Contact Us</p>
                <div>
                  <BiLocationPlus />
                  <span className="_contactUsTxt">Near </span>
                </div>
                <div>
                  <BiPhone />
                  <span className="_contactUsTxt">+29 3233874150</span>
                </div>
                <div>
                  <AiFillMail />
                  <span className="_contactUsTxt">
                    onlinequizservice@gmail.com
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
