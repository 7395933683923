import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBModalFooter,
} from "mdbreact";
import "./login.css";
import Logo from "./../../img/quiz-logo-png-transparent.png";
import { firebaseApp, Provider } from "./../../config/Firebase";
import Drawer from "../../components/drawer/drawer";
import MenuAppBar from "../../components/navbar/navbar";
let database = firebaseApp.database().ref("/");
export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
      value: "",
      helperText: "",
    };
  }
  signup = () => {
    firebaseApp
      .auth()
      .signInWithPopup(Provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        database.child("users/" + user.uid + "/").set({
          user: user.displayName,
          photo: user.photoURL,
          email: user.email,
          uid: user.uid,
          token: token,
        });
        this.props.history.push("/");
        // ...
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    return (
      <div>
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_drawer">
          <Drawer props={this.props} />
        </div>
        <div className="login_main" style={{ marginTop: "150px" }}>
          <MDBContainer>
            <MDBRow center>
              <MDBCol md="5">
                <MDBCard>
                  <MDBCardBody className="mx-4">
                    <div className="text-center">
                      {/* -----------------------------> LOGO <------------------------- */}
                      {/* <img
                      src={Logo}
                      alt="logo"
                      className="_quiz_logo"
                      width="100px"
                    /> */}
                      <br />
                      <br />

                      {/* -----------------------------> HEADING <------------------------- */}
                      <h3 className="dark-grey-text mb-5">
                        <strong>Login</strong>
                      </h3>
                    </div>

                    {/* -----------------------------> SIGN UP BUTTON <------------------------- */}
                    <div className="text-center mb-3">
                      <button
                        type="button"
                        class="login-with-google-btn"
                        style={{ width: "100%" }}
                        onClick={() => this.signup()}
                      >
                        Sign in with Google
                      </button>
                    </div>
                  </MDBCardBody>

                  {/* -----------------------------> SIGN IN BUTTON  <------------------------- */}
                  <MDBModalFooter className="mx-5 pt-3 mb-1">
                    <p className="font-small grey-text d-flex justify-content-end">
                      Create account?
                      <a
                        className="blue-text ml-1"
                        onClick={() => this.props.history.push("signup")}
                      >
                        Sign up
                      </a>
                    </p>
                  </MDBModalFooter>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    );
  }
}
