import React, { Component } from "react";
import { MDBContainer } from "mdbreact";
import Drawer from "./../../components/drawer/drawer";
import Paper from "@material-ui/core/Paper";
import "./AttemptedAns.css";
import { firebaseApp } from "./../../config/Firebase";
import MenuAppBar from "./../../components/navbar/navbar";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBBtn,
  MDBTable,
} from "mdbreact";
import Grid from "@material-ui/core/Grid";
import logo from "./../../img/logo icon 2-01.png";
import quizLogo from "./../../img/quiz.png";
let database = firebaseApp.database().ref("/");
class AttemptedAns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      timing: 0,
      data: props.quiz,
      questionArr: [],
      i: 0,
      currentOpt: false,
      selectedOpt: "",
      time: 0,
      startingDate: "",
      seconds: 40,
      curentuser: {},
      quizKey: "",
      totalQuiz: [],
    };
  }
  componentDidMount() {
    let totalQuiz = [];
    database.child("DailyBaseQuizList/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      totalQuiz.push(data);
      this.setState({ totalQuiz: totalQuiz });
    });

    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        let obj = {
          uid: user.uid,
          attemptedBy: user.displayName,
          photoURL: user.photoURL,
        };
        this.setState({ curentuser: obj });
      } else {
        console.log("No user is signed in.");
      }
    });
  }
  toggle = (nr) => () => {
    let modaliber = "modal" + nr;
    this.setState({
      [modaliber]: !this.state[modaliber],
    });
  };

  ViewResult = () => {
    alert("hello");
  };

  render() {
    let { totalQuiz } = this.state;

    const filterQuizResult = totalQuiz.filter((quiz) => {
      return quiz.quizId
        .toLowerCase()
        .includes(this.state.quizKey.toLowerCase());
    });
    return (
      <div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>

        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_drawer">
          <Drawer props={this.props} />
        </div>
        <br />
        <br />
        <br />
        <br />

        <MDBContainer className="_view_result_container">
          <MDBCard narrow>
            <MDBCardHeader
              className="view view-cascade gradient-card-header peach-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3"
              style={{ marginTop: -30, borderRadius: 10 }}
            >
              <div>
                <img src={logo} style={{ height: 40, width: 40 }} />
              </div>
              <p style={{  paddingTop: 10,
                    color: "white",
                    textAlign: "center",
                    fontWeight:"bold",
                    fontSize:16,
                    width: "100%",}} className="_meritlist_heading">
                PST/JST TEST RESULT
              </p>
            </MDBCardHeader>
            <MDBCardBody cascade>
              <div style={{textAlign:"right"}} className="_search_container">
                <input
                  type="text"
                  placeholder="Enter key key"
                  aria-label="Search"
                  value={this.state.quizKey}
                  onChange={(e) => this.setState({ quizKey: e.target.value })}
                  style={{width:250,padding:3,borderRadius:5}}
                  className="_search_input"
                />
              </div>
              <Paper
                className="quiz_list"
                style={{
                  width: "100%",
                  marginBottom: 10,
                  backgroundColor: "#ffdeff",
                  marginTop:10
                }}
              >
                <span className="_th" style={{ paddingLeft: 50 }}>
                  Class
                </span>
                <span className="_th">Subject</span>
                <span className="_th">Quiz Name</span>
                <span className="_th">Passing Score</span>
                <div
                  
                  className="_result_btn"
                >
                  Rusult
                </div>
              </Paper>
              <MDBTable btn fixed>
                {/* available quiz */}
                {filterQuizResult.length !== 0 ? (
                  filterQuizResult.map((val, i) => {
                    return (
                      <Grid item xs={12} lg={12}>
                        <Paper
                          className="quiz_list"
                          style={{
                            borderLeft: `8px solid #f27c66`,
                            width: "100%",
                            marginBottom: 10,
                          }}
                        >
                          <div className="_quiz_logo">
                            <img src={quizLogo} className="_quiz_logo" />
                          </div>

                          <span className="_td">{val.class}</span>
                          <span className="_td">{val.subject}</span>
                          <span className="_td">{val.title}</span>
                          <span className="_td">{val.passingScore}%</span>
                          <MDBBtn
                            color={"#f27c66"}
                            style={{ backgroundColor: "#f27c66" }}
                            className="_start_button"
                            size="sm"
                            onClick={() =>
                              this.props.history.push("viewresult", {
                                data: val,
                              })
                            }
                          >
                            View Result
                          </MDBBtn>
                        </Paper>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={10} lg={10}>
                    <Paper
                      className="quiz_list"
                      style={{ borderLeft: `8px solid #f27c66` }}
                    >
                      <span>No quiz available!</span>
                    </Paper>
                  </Grid>
                )}
              </MDBTable>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}
export default AttemptedAns;
