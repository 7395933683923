import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AppBar from "@material-ui/core/AppBar";
import { firebaseApp } from "./../../config/Firebase";
import "./drawer.css";
import Logo from "./../../img/quiz-logo-png-transparent.png";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
let database = firebaseApp.database().ref("/");

const useStyles = makeStyles({
  list: {
    width: 250,
    // background-color:  !important;
  },
  fullList: {
    width: "auto",
  },
});
export default function TemporaryDrawer(props) {
  const [currentuser, setcurrentuser] = useState(false);

  useEffect((res) => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        database.child("users/" + user.uid + "/").once("value", (res) => {
          let data = res.val();
          // setcurrentuser(data)
          localStorage.setItem("activeuser", JSON.stringify(data));
          setcurrentuser(data);
        });
      } else {
        console.log("No user is signed in.");
      }
    });
  }, []);

  const logout = () => {
    firebaseApp
      .auth()
      .signOut()
      .then(function () {
        props.props.push("/");
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  };

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    props.props.push("Profile");
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      {/* -----------------------------> SIDE BAR PAGES <------------------------- */}
      <List style={{ marginLeft: "40px", marginTop: "50px" }}>
        <a
          onClick={() => props.props.history.push("/")}
          className="ml-1 link_color"
        >
          <ListItem button style={{ fontFamily: "'Montserrat', sans-serif" }}>
            <div className="_drawerPageLInk">Home</div>
          </ListItem>
        </a>
        <a className="ml-1 link_color">
          <ListItem
            button
            style={{ fontFamily: "'Montserrat', sans-serif" }}
            onClick={() => props.props.history("availablequiz")}
          >
            <div className="_drawerPageLInk">PST/JEST Prep</div>
          </ListItem>
        </a>
        <a
          onClick={() => props.props.history.push("AttemptedAns")}
          className="ml-1 link_color"
        >
          <ListItem button style={{ fontFamily: "'Montserrat', sans-serif" }}>
            <div className="_drawerPageLInk">Result</div>
          </ListItem>
        </a>

        {currentuser ? (
          <a className="ml-1 link_color">
            <ListItem
              button
              style={{ fontFamily: "'Montserrat', sans-serif" }}
              onClick={() => logout()}
            >
              <div className="_drawerPageLInk">Logout</div>
            </ListItem>
          </a>
        ) : (
          <a
            onClick={() => props.props.history.push("login")}
            className="ml-1 link_color"
          >
            <ListItem button style={{ fontFamily: "'Montserrat', sans-serif" }}>
              <div className="_drawerPageLInk">Login</div>
            </ListItem>
          </a>
        )}
      </List>
    </div>
  );

  return (
    <AppBar>
      <div
        style={{
          backgroundColor: "#1a2238",
          padding: 10,
          borderBottom: "1px solid #e6dddb",
        }}
      >
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button>
                    <img src={Logo} style={{ height: 30 }} />
                  </Button>
                  <Button color="inherit" onClick={toggleDrawer(anchor, true)}>
                    <MenuIcon color="rgb(200, 199, 202)" />
                  </Button>

                  {/* <img
                    src={currentuser !== false ? currentuser.photo : null}
                    style={{ borderRadius: "50%" }}
                    className="_photo_url"
                  /> */}
                </div>

                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </AppBar>
  );
}
