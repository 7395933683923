import React from "react";
import { firebaseApp } from "./../../config/Firebase";
import Paper from "@material-ui/core/Paper";
import "./QuizList.css";
import Grid from "@material-ui/core/Grid";
import MenuAppBar from "./../../components/navbar/navbar";
import { MDBBtn } from "mdbreact";
import Drawer from "./../../components/drawer/drawer";
let database = firebaseApp.database().ref("/");

export default class QuizList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      papers: [],
      selected: 0,
      subjectArr: [],
    };
  }

  componentDidMount() {
    let getProps = this.props.location.state;
    let subjectArr = [];
    database
      .child(
        "Categories/" +
          getProps.catId +
          "/" +
          "cat/" +
          getProps.subjectId +
          "/" +
          "className/"
      )
      .on("child_added", (a) => {
        let d = a.val();
        subjectArr.push(d);
        this.setState({ subjectArr: subjectArr });
      });
  }

  render() {
    let { selected, subjectArr } = this.state;
    let data = this.props?.location?.state?.data;
    let theme = this.props.location.state.theme;
    console.log(selected);
    console.log(subjectArr[3].subCat);

    return (
      <div>
        {/* -----------------------------> NAVBAR <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>

        <div className="_drawer">
          <Drawer props={this.props} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_inner_view">
          <Grid container spacing={2} justify="center">
            <Grid item xs={10} lg={10}>
              <select
                className="_search_list"
                // onChange={(e) => this.setState({ selected: e.target.value })}
              >
                {subjectArr.length !== 0
                  ? subjectArr.map((val, i) => {
                      return <option value={i}>{val.title}</option>;
                    })
                  : null}
              </select>
            </Grid>
            {subjectArr.length !== 0 &&
            subjectArr[selected].subCat !== undefined ? (
              subjectArr[selected].subCat.map((val, i) => {
                return (
                  <Grid item xs={10} lg={10}>
                    <Paper
                      className="quiz_list"
                      style={{ borderLeft: `8px solid ${theme}` }}
                    >
                      <MDBBtn
                        color={theme}
                        style={{ backgroundColor: theme }}
                        className="_start_button"
                        size="sm"
                        onClick={() => alert("under development!")}
                      >
                        TAKE QUIZ
                      </MDBBtn>
                    </Paper>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={10} lg={10}>
                <Paper
                  className="quiz_list"
                  style={{ borderLeft: `8px solid ${theme}` }}
                >
                  <span>No quiz available!</span>
                </Paper>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}
