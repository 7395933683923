// import React, { Component } from "react";
// import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
// import Drawer from "./../../components/drawer/drawer";
// import Paper from "@material-ui/core/Paper";
// import "./ChallangeQuiz.css";
// import { firebaseApp } from "./../../config/Firebase";
// import MenuAppBar from "./../../components/navbar/navbar";

// let database = firebaseApp.database().ref("/");
// class PlaRandomQuiz extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             modal14: false,
//             timing: 0,
//             data: props.quiz,
//             questionArr: [],
//             i: 0,
//             currentOpt: false,
//             selectedOpt: "",
//             time: 0,
//             startingDate: "",
//             seconds: 40,
//             curentuser: {},
//             isSubmited: false,
//         };
//     }
//     componentDidMount() {
//         let questions = [{
//             "answer": "were",
//             "option1": "are",
//             "option2": "be",
//             "option3": "do ",
//             "option4": "were",
//             "question": "A: What ____ you doing then? B: Umm... haha... "
//         },
//         {
//             "answer": "doesn't",
//             "option1": "does",
//             "option2": "don't",
//             "option3": "doesn't",
//             "option4": "isn't",
//             "question": "A: Does she like swimming? B: No, she _______"
//         },
//         {
//             "answer": "would",
//             "option1": "are",
//             "option2": "will",
//             "option3": "can",
//             "option4": "would",
//             "question": "A: What _____ you like to drink? B: A glass of water, please."
//         },
//         {
//             "answer": "have",
//             "option1": "have",
//             "option2": "are",
//             "option3": "did",
//             "option4": "had",
//             "question": "A: _____ you ever seen a Golden Eagle? B: Yes, I have. It was in Scotland."
//         },
//         {
//             "answer": "since",
//             "option1": "in ",
//             "option2": "since",
//             "option3": "for",
//             "option4": "during",
//             "question": "A: Have you been in England a long time? B: Yes, several months. I've been here ___ August last year."
//         },
//         {
//             "answer": "was",
//             "option1": "been",
//             "option2": "did",
//             "option3": "am",
//             "option4": "was",
//             "question": "A: What were you doing when I called you yesterday? B: I ___ cleaning the kitchen. That's why I was in a hurry."
//         },
//         {
//             "answer": "faster",
//             "option1": "fastest",
//             "option2": "more fast",
//             "option3": "faster",
//             "option4": "fast",
//             "question": "A: How fast can you read English? B: I'm quite good but Julia is ___ than me."
//         },
//         {
//             "answer": "intends to",
//             "option1": "intends to",
//             "option2": "is",
//             "option3": "going to",
//             "option4": "wants",
//             "question": "A: He's very clever. He says, he ___ be a professor. B: Good luck to him!"
//         },
//         {
//             "answer": "have",
//             "option1": "were",
//             "option2": "have",
//             "option3": "had",
//             "option4": "did",
//             "question": "A: How long __ you been a dancer? B: About six years now. I love it."
//         },
//         {
//             "answer": "does",
//             "option1": "makes",
//             "option2": "does",
//             "option3": "gets",
//             "option4": "works",
//             "question": "A: Who ___ the housework in your home? B: We all share it, but my mother does most of it."
//         },
//         {
//             "answer": "hadn't",
//             "option1": "hasn't",
//             "option2": "hadn't",
//             "option3": "didn't",
//             "option4": "wasn't",
//             "question": "A: Was John ready for his exam last week? B: No, he ___ studied anything at all."
//         },
//         {
//             "answer": "in",
//             "option1": "on",
//             "option2": "of",
//             "option3": "to",
//             "option4": "in",
//             "question": "A: Do you like photography? B: Not really, but I'm quite interested ___ art."
//         },
//         {
//             "answer": "have been",
//             "option1": "am",
//             "option2": "have been",
//             "option3": "was",
//             "option4": "had been",
//             "question": "A: I ___ wanting to work at this job for twenty years. B: That's a long time! Did you never want anything\r\nelse in life?"
//         },
//         {
//             "answer": "work hard",
//             "option1": "work hard",
//             "option2": "work hardly",
//             "option3": "hardly work",
//             "option4": "hard work",
//             "question": "A: John is always busy. He has to ____. B: Yes, I know. So do I."
//         },
//         {
//             "answer": "would",
//             "option1": "do",
//             "option2": "will",
//             "option3": "can",
//             "option4": "would",
//             "question": "A: What ___ happen if all the clocks in London stopped? B: I don't know. What a silly question!"
//         },
//         {
//             "answer": "on",
//             "option1": "on",
//             "option2": "of",
//             "option3": "about",
//             "option4": "in",
//             "question": "A: Do you like the theatre? B: Sometimes, it depends ___ the play."
//         },
//         {
//             "answer": "said",
//             "option1": "told",
//             "option2": "said",
//             "option3": "said us",
//             "option4": "told of us",
//             "question": "A: It was a fantastic party! B: Yes, John ____ it was good."
//         },
//         {
//             "answer": "gets",
//             "option1": "become",
//             "option2": "has",
//             "option3": "gets",
//             "option4": "make",
//             "question": "A: Do you like your teacher? B: Yes, but she always ____ annoyed when I ask questions."
//         },
//         {
//             "answer": "either",
//             "option1": "so",
//             "option2": "either",
//             "option3": "neither",
//             "option4": "as",
//             "question": "A: I don't really like watching TV much. B: John doesn't ____ . That's why I watch it alone."
//         },
//         {
//             "answer": "make us",
//             "option1": "make us",
//             "option2": "let us to",
//             "option3": "don't allow",
//             "option4": "force us",
//             "question": "A: In our school they ____ stay behind late if we don't do our homework. B: That seems very strict."
//         },
//         {
//             "answer": "don't have to",
//             "option1": "mustn't",
//             "option2": "can't",
//             "option3": "don't have to",
//             "option4": "shouldn't",
//             "question": "A: Is the school strict about uniforms? B: No, you ____ wear a uniform."
//         },
//         {
//             "answer": "for",
//             "option1": "to",
//             "option2": "with",
//             "option3": "get",
//             "option4": "for",
//             "question": "A: I'm fed up with all this work. Do you want to go ___ a coffee? B: OK then. There's a café just next door."
//         },
//         {
//             "answer": "must have",
//             "option1": "should have",
//             "option2": "must have",
//             "option3": "had",
//             "option4": "maybe have",
//             "question": "A: She's got a great suntan. B: Yes, she ___ been somewhere sunny on her holiday."
//         },
//         {
//             "answer": "take up",
//             "option1": "take up",
//             "option2": "take after",
//             "option3": "take out",
//             "option4": "take from",
//             "question": "A: I've just started playing tennis. It's great! B: That's good! I think I need to ___ a new sport too."
//         },
//         {
//             "answer": "been working",
//             "option1": "being working",
//             "option2": "be working",
//             "option3": "been working",
//             "option4": "working",
//             "question": "A: I'm tired. I've ___ all day. B: Sit down and I'll get you a cup of tea."
//         },
//         {
//             "answer": "would",
//             "option1": "wouldn't",
//             "option2": "will",
//             "option3": "could",
//             "option4": "would",
//             "question": "A: ______ you mind helping me move this table? B: Not at all. Where shall I put it?"
//         },
//         {
//             "answer": "on coming",
//             "option1": "to come",
//             "option2": "coming for",
//             "option3": "coming",
//             "option4": "on coming",
//             "question": "A: I like shopping alone but my wife insisted _____ too. B: I think it's more fun together too."
//         },
//         {
//             "answer": "will be saying",
//             "option1": "be saying",
//             "option2": "be say",
//             "option3": "will be saying",
//             "option4": "says",
//             "question": "A: Do you know what the Queen is going to say in her speech? B: I imagine she ____ that it has been a \r\ngood year."
//         },
//         {
//             "answer": "make up",
//             "option1": "decide on",
//             "option2": "make up",
//             "option3": "change up",
//             "option4": "think to",
//             "question": "A: Do you think you will take that job? B: I don't know. I can't ___ my mind."
//         },
//         {
//             "answer": "enthusiastic",
//             "option1": "enthusiast",
//             "option2": "enthusiasm",
//             "option3": "enthusiastical",
//             "option4": "enthusiastic",
//             "question": "A: What did she say to your idea about joining a sports club? B: She was really _____ ."
//         },
//         {
//             "answer": "am getting",
//             "option1": "am getting",
//             "option2": "get",
//             "option3": "am doing",
//             "option4": "have",
//             "question": "A: What are you doing on Saturday afternoon? B: I __ my hair done."
//         },
//         {
//             "answer": "didn’t have",
//             "option1": "didn’t have",
//             "option2": "wouldn’t have had",
//             "option3": "hadn't had",
//             "option4": "hadn't",
//             "question": "A: How do you feel? B: Not good. I wish I ____ that big meal last night."
//         },
//         {
//             "answer": "rather",
//             "option1": "rather",
//             "option2": "prefer",
//             "option3": "like",
//             "option4": "want",
//             "question": "A: Would you like to go out tonight? B: Not really – I'd ____ stay at home and watch TV."
//         },
//         {
//             "answer": "hadn't",
//             "option1": "wasn't",
//             "option2": "hadn't",
//             "option3": "weren't",
//             "option4": "would not have",
//             "question": "A: So Jack worked hard to get promotion, didn't he? B: Yes, If he ____ worked so hard, he might have lost \r\nhis job."
//         },
//         {
//             "answer": "to do",
//             "option1": "to make",
//             "option2": "doing",
//             "option3": "to do",
//             "option4": "making",
//             "question": "A: Why did you arrive so late? B: We stopped _____ some shopping along the way."
//         },
//         {
//             "answer": "have",
//             "option1": "have",
//             "option2": "make",
//             "option3": "attend to",
//             "option4": "go on",
//             "question": "A: When are you going to see your dentist? B: I ____ an appointment on July 24th."
//         },
//         {
//             "answer": "which",
//             "option1": "which",
//             "option2": "it",
//             "option3": "who",
//             "option4": "what",
//             "question": "A: What is a platypus? B: It's an animal ___ lives near rivers in Australia. It's quite rare."
//         },
//         {
//             "answer": "unless",
//             "option1": "if only",
//             "option2": "if",
//             "option3": "supposing",
//             "option4": "unless",
//             "question": "A: ____ you do as you are told, you will not be allowed in this class. B: OK then, if you insist."
//         },
//         {
//             "answer": "better",
//             "option1": "good",
//             "option2": "well",
//             "option3": "better",
//             "option4": "should",
//             "question": "A: All flights from Manchester have been cancelled. B: I suppose you'd ____ take the train then."
//         },
//         {
//             "answer": "lack",
//             "option1": "have lack",
//             "option2": "lack of",
//             "option3": "lack to",
//             "option4": "lack",
//             "question": "A: He seems very confident of success. B: Yes. Well, he doesn't ___ courage."
//         },
//         {
//             "answer": "all of the above",
//             "option1": "tried, luck",
//             "option2": "attempted, avail",
//             "option3": "given, result",
//             "option4": "all of the above",
//             "question": "I have /______/ my best to no /______/."
//         },
//         {
//             "option1": "a key to success",
//             "option2": "an important factor",
//             "option3": "not enough",
//             "question": "Perseverance is /_____/. She needs more than that."
//         },
//         {
//             "answer": "none of the above",
//             "option1": "done",
//             "option2": "did",
//             "option3": "doing",
//             "option4": "none of the above",
//             "question": "What do you /_____/ in the evening? If you are free, sometimes come over and play."
//         },
//         {
//             "answer": "are you doing",
//             "option1": "do you do ",
//             "option2": "are you doing",
//             "option3": "have you done",
//             "option4": "none of the above",
//             "question": "What /_____/ today evening? If you are free, come over and play."
//         },
//         {
//             "answer": "is taking",
//             "option1": "takes",
//             "option2": "is taking",
//             "option3": "has taken",
//             "option4": "none of the above",
//             "question": "Sorry, she can’t come to the phone. She /_____/ a bath."
//         },
//         {
//             "answer": "should cry",
//             "option1": "cry",
//             "option2": "should cry",
//             "option3": "are crying",
//             "option4": "none of the above",
//             "question": "All babies /_____/ when they are born. This shows their healthy state."
//         },
//         {
//             "answer": "cry",
//             "option1": "cry",
//             "option2": "should cry",
//             "option3": "are crying",
//             "option4": "none of the above",
//             "question": "Healthy babies /_____/ when they are born."
//         },
//         {
//             "answer": "is wearing",
//             "option1": "wears",
//             "option2": "is wearing",
//             "option3": "wear",
//             "option4": "none of the above",
//             "question": "Jane /_____/ her grey jeans today, but usually she wears a skirt or a dress."
//         },
//         {
//             "answer": "is trying",
//             "option1": "tries",
//             "option2": "is trying",
//             "option3": "tried",
//             "option4": "none of the above",
//             "question": "Don’t make such a hassle. Ferrero /_____/ to study for his upcoming test."
//         },
//         {
//             "answer": "Try",
//             "option1": "Try",
//             "option2": "Tries",
//             "option3": "Is trying",
//             "option4": "none of the above",
//             "question": "Roger has a test soon. /_____/ not to make a hassle."
//         },
//         {
//             "answer": "keeping warm",
//             "option1": "heating up",
//             "option2": "keeping warm",
//             "option3": "boiling",
//             "option4": "none of the above",
//             "question": "It is a chilly night in Montreal. I guess Meherjan is /_______/ by making a bonfire."
//         },
//         {
//             "answer": "erosion, pollution",
//             "option1": "pollution, pollution",
//             "option2": "erosion, pollution",
//             "option3": "degradation, erosion",
//             "option4": "all of the above",
//             "question": "Soil /_____/ is a serious problem due to /_____/."
//         },
//         {
//             "answer": "make us some tea",
//             "option1": "make us some tea",
//             "option2": "call the doctor",
//             "option3": "call for an\r\nambulance",
//             "option4": "none of the above",
//             "question": "We just came fighting through a blizzard, and Gregory has a severe cold. Meg, can you please /______/ for now? We already called for a doctor. He will be here once the weather calms down."
//         },
//         {
//             "answer": "talent",
//             "option1": "knock",
//             "option2": "skill",
//             "option3": "talent",
//             "option4": "none of the above",
//             "question": "Wow, you mastered this technique in only an hour. You’ve got a knack for karate, let me tell you!\r\nWhat does the word “knack” mean here?\r\n"
//         },
//         {
//             "answer": "Senku is \r\nmetaphorically\r\nbeing compared\r\nto a light",
//             "option1": "Senku is \r\nliterally a\r\nlight",
//             "option2": "Senku is \r\nmetaphorically\r\nbeing compared\r\nto a light",
//             "option3": "Senku's family\r\nfamily members\r\ndo not have\r\nelectricity",
//             "option4": "none of the above",
//             "question": "Senku is the light that illuminates his family.\r\n \r\nWhat does the sentence mean?\r\n"
//         }];
//         let currentQuiz = localStorage.getItem("activeQuiz");
//         currentQuiz = JSON.parse(currentQuiz);
//         if (currentQuiz === null) {
//             let questionArr = [



//             ];
//             for (var i = 0; i < questions.length; i++) {
//                 let obj = {
//                     q: questions[i].question,
//                     options: [
//                         questions[i].option1,
//                         questions[i].option2,
//                         questions[i].option3,
//                         questions[i].option4,
//                         questions[i].option5,
//                     ],
//                     correct: questions[i].answer,
//                 };
//                 questionArr.push(obj);
//                 this.setState({ questionArr: questionArr });
//             }
//         } else {
//             this.setState({ questionArr: currentQuiz });
//         }

//         firebaseApp.auth().onAuthStateChanged((user) => {
//             if (user) {
//                 let obj = {
//                     uid: user.uid,
//                     attemptedBy: user.displayName,
//                     photoURL: user.photoURL,
//                 };
//                 this.setState({ curentuser: obj });

//                 database
//                     .child(
//                         "DailyBaseQuizList/" + "hello@123" + "/" + "AttemptedList/"
//                     )
//                     .on("child_added", (check) => {
//                         let value = check.val();
//                         if (value.userid === user.uid) {
//                             console.log("you already submit quiz");
//                             this.setState({ isSubmited: true });
//                         }
//                     });
//             } else {
//                 console.log("No user is signed in.");
//             }
//         });
//     }
//     toggle = (nr) => () => {
//         let modaliber = "modal" + nr;
//         this.setState({
//             [modaliber]: !this.state[modaliber],
//         });
//     };

//     selectOpiton = async (data, index) => {
//         let quiz = this.state.questionArr;
//         quiz[index].selectedOption = index;
//         quiz[index].selectedValue = data;
//         localStorage.setItem("activeQuiz", JSON.stringify(quiz));
//         this.setState({ questionArr: quiz });
//     };

//     submitQuiz = () => {
//         let getlocalData = localStorage.getItem("activeQuiz");
//         getlocalData = JSON.parse(getlocalData);
//         var correct = 0;
//         var wrong = 0;
//         var misssed = 0;
//         let attemptedQuiz = getlocalData;
//         let { curentuser } = this.state;
//         for (var i = 0; i < attemptedQuiz.length; i++) {
//             if (attemptedQuiz[i].correct === attemptedQuiz[i].seletedValue) {
//                 correct++;
//             }
//             if (attemptedQuiz[i].selectedValue) {
//                 wrong++;
//             }
//             if (attemptedQuiz[i].selectedValue === undefined) {
//                 misssed++;
//             }
//         }
//         let resultData = {
//             correct: correct,
//             wrong: wrong,
//             misssed: misssed,
//             attemptedBy: curentuser,
//             attemptedBy: curentuser.attemptedBy,
//             profile: curentuser.photoURL,
//             userid: curentuser.uid,
//             attemptedQuiz: getlocalData,
//         };

//         let confirm = window.confirm(
//             "Are you want to submit quiz? \n Click ok to see your result"
//         );
//         if (confirm) {
//             database
//                 .child(
//                     "DailyBaseQuizList/" + this.props.quizUid + "/" + "AttemptedList"
//                 )
//                 .push(resultData);
//             localStorage.removeItem("activeQuiz");
//             this.props.props.push("AttemptedAns", { quizKey: this.props.quizKey });
//         }
//     };
//     render() {
//         let { questionArr, isSubmited } = this.state;

//         return (
//             <div>
//                 {/* -----------------------------> DRAWER <------------------------- */}
//                 <div className="_menuAppBar">
//                     <MenuAppBar props={this.props.props} />
//                 </div>
//                 {/* -----------------------------> DRAWER <------------------------- */}
//                 <div className="_drawer">
//                     <Drawer props={this.props} />
//                 </div>
//                 <MDBContainer>
//                     {!isSubmited ? (
//                         <>
//                             {questionArr.length !== 0 ? (
//                                 <>
//                                     {questionArr.length !== 0 ? (
//                                         <div className="home_card_main">
//                                             {/* -----------------------------> QUESTIONS <------------------------- */}
//                                             {questionArr.map((qData, i) => {
//                                                 return (
//                                                     <div>
//                                                         <MDBRow>
//                                                             <MDBCol sm="8">
//                                                                 <MDBRow>
//                                                                     <MDBCol size="12">
//                                                                         <p
//                                                                             style={{
//                                                                                 display: "flex",
//                                                                                 flexDirection: "row",
//                                                                                 flex: 1,
//                                                                                 width: "100%",
//                                                                                 fontSize: 14,
//                                                                                 marginTop: 15,
//                                                                                 marginBottom: -5,
//                                                                             }}
//                                                                         >
//                                                                             <span>
//                                                                                 <span>Q.{i + 1}).</span>
//                                                                             </span>
//                                                                             <span>{qData.q}</span>
//                                                                         </p>
//                                                                     </MDBCol>
//                                                                 </MDBRow>
//                                                             </MDBCol>
//                                                         </MDBRow>

//                                                         {/* -----------------------------> RENDER QUESTION <------------------------- */}
//                                                         <MDBRow>
//                                                             <MDBCol sm="8">
//                                                                 <Paper>
//                                                                     <MDBContainer>
//                                                                         <MDBRow>
//                                                                             <MDBCol sm="10">
//                                                                                 {/* <p className="question_show">{questionArr[0].question}</p> */}
//                                                                             </MDBCol>
//                                                                         </MDBRow>
//                                                                     </MDBContainer>
//                                                                 </Paper>
//                                                             </MDBCol>
//                                                             <MDBCol sm="2"></MDBCol>
//                                                         </MDBRow>
//                                                         <br />

//                                                         {/* -----------------------------> OPTIONS <------------------------- */}

//                                                         {questionArr[i].options.map((val, j) => {
//                                                             return val !== null && val !== undefined ? (
//                                                                 <MDBRow
//                                                                     key={j}
//                                                                     onClick={() =>
//                                                                         questionArr[i].seletedValue
//                                                                             ? null
//                                                                             : this.selectOpiton(val, i)
//                                                                     }
//                                                                 >
//                                                                     <MDBCol sm="8">
//                                                                         <Paper
//                                                                             className="options_main"
//                                                                             style={
//                                                                                 questionArr[i].selectedValue &&
//                                                                                     questionArr[i].selectedValue === val
//                                                                                     ? {
//                                                                                         backgroundColor:
//                                                                                             "rgb(105 186 244)",
//                                                                                     }
//                                                                                     : { backgroundColor: "white" }
//                                                                             }
//                                                                         >
//                                                                             <div className="_options_label">
//                                                                                 {j === 0 ? "A." : null}
//                                                                                 {j === 1 ? "B." : null}
//                                                                                 {j === 2 ? "C." : null}
//                                                                                 {j === 3 ? "D." : null}
//                                                                                 {j === 4 ? "E." : null}
//                                                                                 {j === 5 ? "F." : null}
//                                                                             </div>
//                                                                             <div className="options"> {val}</div>
//                                                                         </Paper>
//                                                                     </MDBCol>
//                                                                 </MDBRow>
//                                                             ) : null;
//                                                         })}
//                                                     </div>
//                                                 );
//                                             })}

//                                             <br />
//                                             <br />

//                                             {/* -----------------------------> BUTTONS <------------------------- */}
//                                             <MDBRow>
//                                                 <MDBCol sm="8">
//                                                     <MDBRow>
//                                                         <p
//                                                             style={{
//                                                                 textAlign: "right",
//                                                                 width: "100%",
//                                                                 marginRight: 10,
//                                                             }}
//                                                         >
//                                                             <button className="next_btn">
//                                                                 <MDBRow>
//                                                                     <MDBCol onClick={() => this.submitQuiz()}>
//                                                                         <span className="btn_txt">Submit</span>
//                                                                     </MDBCol>
//                                                                 </MDBRow>
//                                                             </button>
//                                                         </p>
//                                                     </MDBRow>
//                                                 </MDBCol>
//                                                 <MDBCol sm="2"></MDBCol>
//                                             </MDBRow>
//                                         </div>
//                                     ) : null}
//                                 </>
//                             ) : null}
//                         </>
//                     ) : (
//                         <div className="_alrady_submited">
//                             <p>You Alrady Submited quiz</p>
//                             <p>Quiz key:{this.props.quizKey}</p>

//                             <MDBBtn
//                                 color="#f27c66"
//                                 style={{ backgroundColor: "#f27c66" }}
//                                 className="_start_button"
//                                 size="sm"
//                                 onClick={() => this.props.props.push("AttemptedAns")}
//                             >
//                                 View Score
//               </MDBBtn>
//                         </div>
//                     )}
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                 </MDBContainer>
//             </div>
//         );
//     }
// }
// export default PlaRandomQuiz;
import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Drawer from "./../../components/drawer/drawer";
import Paper from "@material-ui/core/Paper";
import LeftIcon from "./../../img/left-arrow.png";
import RightIcon from "./../../img/right-arrow.png";
import { Timer } from "react-countdown-clock-timer";
import "./ChallangeQuiz.css";
// import firebaseApp from "./../../config/Firebase";
import { firebaseApp } from "./../../config/Firebase";

import moment from "moment";

let database = firebaseApp.database().ref("/");
class Playquiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal14: false,
            timing: 0,
            data: [{
                "answer": "were",
                "option1": "are",
                "option2": "be",
                "option3": "do ",
                "option4": "were",
                "question": "A: What ____ you doing then? B: Umm... haha... "
            },
            {
                "answer": "doesn't",
                "option1": "does",
                "option2": "don't",
                "option3": "doesn't",
                "option4": "isn't",
                "question": "A: Does she like swimming? B: No, she _______"
            },
            {
                "answer": "would",
                "option1": "are",
                "option2": "will",
                "option3": "can",
                "option4": "would",
                "question": "A: What _____ you like to drink? B: A glass of water, please."
            },
            {
                "answer": "have",
                "option1": "have",
                "option2": "are",
                "option3": "did",
                "option4": "had",
                "question": "A: _____ you ever seen a Golden Eagle? B: Yes, I have. It was in Scotland."
            },
            {
                "answer": "since",
                "option1": "in ",
                "option2": "since",
                "option3": "for",
                "option4": "during",
                "question": "A: Have you been in England a long time? B: Yes, several months. I've been here ___ August last year."
            },
            {
                "answer": "was",
                "option1": "been",
                "option2": "did",
                "option3": "am",
                "option4": "was",
                "question": "A: What were you doing when I called you yesterday? B: I ___ cleaning the kitchen. That's why I was in a hurry."
            },
            {
                "answer": "faster",
                "option1": "fastest",
                "option2": "more fast",
                "option3": "faster",
                "option4": "fast",
                "question": "A: How fast can you read English? B: I'm quite good but Julia is ___ than me."
            },
            {
                "answer": "intends to",
                "option1": "intends to",
                "option2": "is",
                "option3": "going to",
                "option4": "wants",
                "question": "A: He's very clever. He says, he ___ be a professor. B: Good luck to him!"
            },
            {
                "answer": "have",
                "option1": "were",
                "option2": "have",
                "option3": "had",
                "option4": "did",
                "question": "A: How long __ you been a dancer? B: About six years now. I love it."
            },
            {
                "answer": "does",
                "option1": "makes",
                "option2": "does",
                "option3": "gets",
                "option4": "works",
                "question": "A: Who ___ the housework in your home? B: We all share it, but my mother does most of it."
            },
            {
                "answer": "hadn't",
                "option1": "hasn't",
                "option2": "hadn't",
                "option3": "didn't",
                "option4": "wasn't",
                "question": "A: Was John ready for his exam last week? B: No, he ___ studied anything at all."
            },
            {
                "answer": "in",
                "option1": "on",
                "option2": "of",
                "option3": "to",
                "option4": "in",
                "question": "A: Do you like photography? B: Not really, but I'm quite interested ___ art."
            },
            {
                "answer": "have been",
                "option1": "am",
                "option2": "have been",
                "option3": "was",
                "option4": "had been",
                "question": "A: I ___ wanting to work at this job for twenty years. B: That's a long time! Did you never want anything\r\nelse in life?"
            },
            {
                "answer": "work hard",
                "option1": "work hard",
                "option2": "work hardly",
                "option3": "hardly work",
                "option4": "hard work",
                "question": "A: John is always busy. He has to ____. B: Yes, I know. So do I."
            },
            {
                "answer": "would",
                "option1": "do",
                "option2": "will",
                "option3": "can",
                "option4": "would",
                "question": "A: What ___ happen if all the clocks in London stopped? B: I don't know. What a silly question!"
            },
            {
                "answer": "on",
                "option1": "on",
                "option2": "of",
                "option3": "about",
                "option4": "in",
                "question": "A: Do you like the theatre? B: Sometimes, it depends ___ the play."
            },
            {
                "answer": "said",
                "option1": "told",
                "option2": "said",
                "option3": "said us",
                "option4": "told of us",
                "question": "A: It was a fantastic party! B: Yes, John ____ it was good."
            },
            {
                "answer": "gets",
                "option1": "become",
                "option2": "has",
                "option3": "gets",
                "option4": "make",
                "question": "A: Do you like your teacher? B: Yes, but she always ____ annoyed when I ask questions."
            },
            {
                "answer": "either",
                "option1": "so",
                "option2": "either",
                "option3": "neither",
                "option4": "as",
                "question": "A: I don't really like watching TV much. B: John doesn't ____ . That's why I watch it alone."
            },
            {
                "answer": "make us",
                "option1": "make us",
                "option2": "let us to",
                "option3": "don't allow",
                "option4": "force us",
                "question": "A: In our school they ____ stay behind late if we don't do our homework. B: That seems very strict."
            },
            {
                "answer": "don't have to",
                "option1": "mustn't",
                "option2": "can't",
                "option3": "don't have to",
                "option4": "shouldn't",
                "question": "A: Is the school strict about uniforms? B: No, you ____ wear a uniform."
            },
            {
                "answer": "for",
                "option1": "to",
                "option2": "with",
                "option3": "get",
                "option4": "for",
                "question": "A: I'm fed up with all this work. Do you want to go ___ a coffee? B: OK then. There's a café just next door."
            },
            {
                "answer": "must have",
                "option1": "should have",
                "option2": "must have",
                "option3": "had",
                "option4": "maybe have",
                "question": "A: She's got a great suntan. B: Yes, she ___ been somewhere sunny on her holiday."
            },
            {
                "answer": "take up",
                "option1": "take up",
                "option2": "take after",
                "option3": "take out",
                "option4": "take from",
                "question": "A: I've just started playing tennis. It's great! B: That's good! I think I need to ___ a new sport too."
            },
            {
                "answer": "been working",
                "option1": "being working",
                "option2": "be working",
                "option3": "been working",
                "option4": "working",
                "question": "A: I'm tired. I've ___ all day. B: Sit down and I'll get you a cup of tea."
            },
            {
                "answer": "would",
                "option1": "wouldn't",
                "option2": "will",
                "option3": "could",
                "option4": "would",
                "question": "A: ______ you mind helping me move this table? B: Not at all. Where shall I put it?"
            },
            {
                "answer": "on coming",
                "option1": "to come",
                "option2": "coming for",
                "option3": "coming",
                "option4": "on coming",
                "question": "A: I like shopping alone but my wife insisted _____ too. B: I think it's more fun together too."
            },
            {
                "answer": "will be saying",
                "option1": "be saying",
                "option2": "be say",
                "option3": "will be saying",
                "option4": "says",
                "question": "A: Do you know what the Queen is going to say in her speech? B: I imagine she ____ that it has been a \r\ngood year."
            },
            {
                "answer": "make up",
                "option1": "decide on",
                "option2": "make up",
                "option3": "change up",
                "option4": "think to",
                "question": "A: Do you think you will take that job? B: I don't know. I can't ___ my mind."
            },
            {
                "answer": "enthusiastic",
                "option1": "enthusiast",
                "option2": "enthusiasm",
                "option3": "enthusiastical",
                "option4": "enthusiastic",
                "question": "A: What did she say to your idea about joining a sports club? B: She was really _____ ."
            },
            {
                "answer": "am getting",
                "option1": "am getting",
                "option2": "get",
                "option3": "am doing",
                "option4": "have",
                "question": "A: What are you doing on Saturday afternoon? B: I __ my hair done."
            },
            {
                "answer": "didn’t have",
                "option1": "didn’t have",
                "option2": "wouldn’t have had",
                "option3": "hadn't had",
                "option4": "hadn't",
                "question": "A: How do you feel? B: Not good. I wish I ____ that big meal last night."
            },
            {
                "answer": "rather",
                "option1": "rather",
                "option2": "prefer",
                "option3": "like",
                "option4": "want",
                "question": "A: Would you like to go out tonight? B: Not really – I'd ____ stay at home and watch TV."
            },
            {
                "answer": "hadn't",
                "option1": "wasn't",
                "option2": "hadn't",
                "option3": "weren't",
                "option4": "would not have",
                "question": "A: So Jack worked hard to get promotion, didn't he? B: Yes, If he ____ worked so hard, he might have lost \r\nhis job."
            },
            {
                "answer": "to do",
                "option1": "to make",
                "option2": "doing",
                "option3": "to do",
                "option4": "making",
                "question": "A: Why did you arrive so late? B: We stopped _____ some shopping along the way."
            },
            {
                "answer": "have",
                "option1": "have",
                "option2": "make",
                "option3": "attend to",
                "option4": "go on",
                "question": "A: When are you going to see your dentist? B: I ____ an appointment on July 24th."
            },
            {
                "answer": "which",
                "option1": "which",
                "option2": "it",
                "option3": "who",
                "option4": "what",
                "question": "A: What is a platypus? B: It's an animal ___ lives near rivers in Australia. It's quite rare."
            },
            {
                "answer": "unless",
                "option1": "if only",
                "option2": "if",
                "option3": "supposing",
                "option4": "unless",
                "question": "A: ____ you do as you are told, you will not be allowed in this class. B: OK then, if you insist."
            },
            {
                "answer": "better",
                "option1": "good",
                "option2": "well",
                "option3": "better",
                "option4": "should",
                "question": "A: All flights from Manchester have been cancelled. B: I suppose you'd ____ take the train then."
            },
            {
                "answer": "lack",
                "option1": "have lack",
                "option2": "lack of",
                "option3": "lack to",
                "option4": "lack",
                "question": "A: He seems very confident of success. B: Yes. Well, he doesn't ___ courage."
            },
            {
                "answer": "all of the above",
                "option1": "tried, luck",
                "option2": "attempted, avail",
                "option3": "given, result",
                "option4": "all of the above",
                "question": "I have /______/ my best to no /______/."
            },
            {
                "option1": "a key to success",
                "option2": "an important factor",
                "option3": "not enough",
                "question": "Perseverance is /_____/. She needs more than that."
            },
            {
                "answer": "none of the above",
                "option1": "done",
                "option2": "did",
                "option3": "doing",
                "option4": "none of the above",
                "question": "What do you /_____/ in the evening? If you are free, sometimes come over and play."
            },
            {
                "answer": "are you doing",
                "option1": "do you do ",
                "option2": "are you doing",
                "option3": "have you done",
                "option4": "none of the above",
                "question": "What /_____/ today evening? If you are free, come over and play."
            },
            {
                "answer": "is taking",
                "option1": "takes",
                "option2": "is taking",
                "option3": "has taken",
                "option4": "none of the above",
                "question": "Sorry, she can’t come to the phone. She /_____/ a bath."
            },
            {
                "answer": "should cry",
                "option1": "cry",
                "option2": "should cry",
                "option3": "are crying",
                "option4": "none of the above",
                "question": "All babies /_____/ when they are born. This shows their healthy state."
            },
            {
                "answer": "cry",
                "option1": "cry",
                "option2": "should cry",
                "option3": "are crying",
                "option4": "none of the above",
                "question": "Healthy babies /_____/ when they are born."
            },
            {
                "answer": "is wearing",
                "option1": "wears",
                "option2": "is wearing",
                "option3": "wear",
                "option4": "none of the above",
                "question": "Jane /_____/ her grey jeans today, but usually she wears a skirt or a dress."
            },
            {
                "answer": "is trying",
                "option1": "tries",
                "option2": "is trying",
                "option3": "tried",
                "option4": "none of the above",
                "question": "Don’t make such a hassle. Ferrero /_____/ to study for his upcoming test."
            },
            {
                "answer": "Try",
                "option1": "Try",
                "option2": "Tries",
                "option3": "Is trying",
                "option4": "none of the above",
                "question": "Roger has a test soon. /_____/ not to make a hassle."
            },
            {
                "answer": "keeping warm",
                "option1": "heating up",
                "option2": "keeping warm",
                "option3": "boiling",
                "option4": "none of the above",
                "question": "It is a chilly night in Montreal. I guess Meherjan is /_______/ by making a bonfire."
            }],
            questionArr: [],
            num: 0,
            currentOpt: false,
            selectedOpt: "",
            time: 0,
            startingDate: "",
            seconds: 40,
        };
    }
    componentDidMount() {
        let questions = [
            {
                "answer": "were",
                "option1": "are",
                "option2": "be",
                "option3": "do ",
                "option4": "were",
                "question": "A: What ____ you doing then? B: Umm... haha... "
            },
            {
                "answer": "doesn't",
                "option1": "does",
                "option2": "don't",
                "option3": "doesn't",
                "option4": "isn't",
                "question": "A: Does she like swimming? B: No, she _______"
            },
            {
                "answer": "would",
                "option1": "are",
                "option2": "will",
                "option3": "can",
                "option4": "would",
                "question": "A: What _____ you like to drink? B: A glass of water, please."
            },
            {
                "answer": "have",
                "option1": "have",
                "option2": "are",
                "option3": "did",
                "option4": "had",
                "question": "A: _____ you ever seen a Golden Eagle? B: Yes, I have. It was in Scotland."
            },
            {
                "answer": "since",
                "option1": "in ",
                "option2": "since",
                "option3": "for",
                "option4": "during",
                "question": "A: Have you been in England a long time? B: Yes, several months. I've been here ___ August last year."
            },
            {
                "answer": "was",
                "option1": "been",
                "option2": "did",
                "option3": "am",
                "option4": "was",
                "question": "A: What were you doing when I called you yesterday? B: I ___ cleaning the kitchen. That's why I was in a hurry."
            },
            {
                "answer": "faster",
                "option1": "fastest",
                "option2": "more fast",
                "option3": "faster",
                "option4": "fast",
                "question": "A: How fast can you read English? B: I'm quite good but Julia is ___ than me."
            },
            {
                "answer": "intends to",
                "option1": "intends to",
                "option2": "is",
                "option3": "going to",
                "option4": "wants",
                "question": "A: He's very clever. He says, he ___ be a professor. B: Good luck to him!"
            },
            {
                "answer": "have",
                "option1": "were",
                "option2": "have",
                "option3": "had",
                "option4": "did",
                "question": "A: How long __ you been a dancer? B: About six years now. I love it."
            },
            {
                "answer": "does",
                "option1": "makes",
                "option2": "does",
                "option3": "gets",
                "option4": "works",
                "question": "A: Who ___ the housework in your home? B: We all share it, but my mother does most of it."
            },
           


        ];
        console.log(questions);

        let getOpeningTime = localStorage.getItem("quizTimer");
        getOpeningTime = JSON.parse(getOpeningTime);
        if (getOpeningTime === null) {
            localStorage.setItem(
                "quizTimer",
                JSON.stringify({
                    startingTime: {
                        seconds: new Date().getSeconds(),
                        milliseconds: new Date().getMilliseconds(),
                    },
                })
            );
        }
        // let  = this.props.location.state.quiz.category;
        // qustions arrangement
        let questionArr = [];
        for (var i = 0; i < questions.length; i++) {
            let obj = {
                q: questions[i].question,
                options: [
                    questions[i].option1,
                    questions[i].option2,
                    questions[i].option3,
                    questions[i].option4,
                ],
                correct: questions[i].answer,
            };
            questionArr.push(obj);
            this.setState({ questionArr: questionArr });
        }
        // // get active quiz
        let getactiveQuiz = localStorage.getItem("activeQuiz");
        getactiveQuiz = JSON.parse(getactiveQuiz);
        if (getactiveQuiz === null) {
            let obj = {
                quiz: questionArr,
                timing: (questionArr.length * 40) / 60,
                num: 0,
            };
            this.setState({
                questionArr: questionArr,
            });
            localStorage.setItem("activeQuiz", JSON.stringify(obj));
        } 
        // else {
        //     if (getactiveQuiz.category !== this.props.location.state.quiz.category) {
        //         let quizData = {
        //             quiz: questionArr,
        //             // category: category,
        //             timing: (questionArr.length * 40) / 60,
        //         };
        //         localStorage.setItem("activeQuiz", JSON.stringify(quizData));
        //     } else {
        //         localStorage.setItem("activeQuiz", JSON.stringify(getactiveQuiz));
        //         console.log(getactiveQuiz);
        //         this.setState({
        //             questionArr: getactiveQuiz.quiz,
        //             category: getactiveQuiz.category,
        //             num: getactiveQuiz.num,
        //         });
            // }
        // }
    }
    toggle = (nr) => () => {
        let modalNumber = "modal" + nr;
        this.setState({
            [modalNumber]: !this.state[modalNumber],
        });
    };

    selectOpiton = async (data, index) => {
        let quiz = this.state.questionArr;
        let { num, questionArr } = this.state;
        if (num <= questionArr.length) {
            this.setState({ currentOpt: index, selectedOpt: data });
        } else {
            alert("already done");
        }
        if (quiz[num - 1] !== undefined && quiz[num - 1].selectedValue === "") {
            quiz[num - 1].selectedOption = index;
            quiz[num - 1].selectedValue = data;
            this.setState({ questionArr: quiz, next: false });
        }
    };

    NextQuestion = () => {
        let { num, questionArr, selectedOpt } = this.state;
        questionArr[num].seletedValue = selectedOpt;
        let getlocalData = localStorage.getItem("activeQuiz");
        getlocalData = JSON.parse(getlocalData);
        getlocalData.quiz = questionArr;
        getlocalData.num = num;

        let getOpeningTime = localStorage.getItem("quizTimer");
        getOpeningTime = JSON.parse(getOpeningTime);
        let timeSpent = {
            sec: new Date().getSeconds() - getOpeningTime.startingTime.seconds,
            millise:
                new Date().getMilliseconds() - getOpeningTime.startingTime.milliseconds,
        };
        questionArr[num].timeSpent = timeSpent;
        localStorage.setItem("activeQuiz", JSON.stringify(getlocalData));
        localStorage.setItem(
            "quizTimer",
            JSON.stringify({
                startingTime: {
                    seconds: new Date().getSeconds(),
                    milliseconds: new Date().getMilliseconds(),
                },
            })
        );
        this.setState({ selectOpiton: "" });
        if (num <= questionArr.length - 2) {
            // let { num, questionArr } = this.state;
            this.setState({ num: num + 1, selectOpiton: "" });
        } else {
            alert("quiz end");
        }
        if (num < questionArr.length - 1) {
            this.setState({ seconds: this.state.seconds + 0.0001 });
        }
    };

    submitQuiz = () => {
        let getlocalData = localStorage.getItem("activeQuiz");
        getlocalData = JSON.parse(getlocalData);
        var marks = 0;
        var correct = 0;
        var wrong = 0;
        
        let attemptedQuiz = getlocalData.quiz;
        console.log("============getlocalData===========>",attemptedQuiz)
        for (var i = 0; i < attemptedQuiz.length; i++) {
            if (attemptedQuiz[i].correct === attemptedQuiz[i].seletedValue) {
                correct++;
                getlocalData.correct = correct;

                if (attemptedQuiz[i].timeSpent.sec < 30) {
                    var spentsec = attemptedQuiz[i].timeSpent.sec * 1000;
                    var spentmilsec = attemptedQuiz[i].timeSpent.millise;
                    var totalSpentMileseconds = spentmilsec + spentmilsec;

                    var thirtysecondstomiliseconds = 30 * 1000;
                    var remainsMileseconds =
                        thirtysecondstomiliseconds - totalSpentMileseconds;

                    var timingMarks = 0.25;
                    var permilesondsMark = timingMarks / thirtysecondstomiliseconds;
                    var timingMarksPerQ = remainsMileseconds * permilesondsMark;
                    marks = marks + 0.75 + timingMarksPerQ;
                    getlocalData.marks = marks;
                } else {
                    marks = marks + 0.75;
                    getlocalData.marks = marks;
                }
            } else {
                wrong++;
                getlocalData.wrong = wrong;
            }
        }

        getlocalData.date = moment().format("DD-MM-YYYY");
        let activeuser = localStorage.getItem("activeuser");
        activeuser = JSON.parse(activeuser);

        
        let percentage = (marks / attemptedQuiz.length) * 100;
       

        database
            .child("users/" + activeuser.uid + "/" + "acceptedChallenge/")
            .push(getlocalData);
        database
            .child("RankingList/")
            .push({
                userData: activeuser,
                correct: correct,
                wrong: wrong,
                marks: marks,
                // category: getlocalData.category,
            });

        // localStorage.removeItem("activeQuiz");
        // localStorage.removeItem("quizTimer");
        // this.props.history.push("Home");
    };

    render() {
        let { data, questionArr, num, currentOpt, selectedOpt } = this.state;
        // let questions = this.props.location.state.quiz.quiz;
        const { timerActive } = this.state;

        const OPTIONS = { prefix: "seconds elapsed!", delay: 100, reset: 30 };
        let activeuser = localStorage.getItem("activeuser");
        activeuser = JSON.parse(activeuser);
        console.log("------activeuser-------->",activeuser)
        return (
            <div>
                {/* -----------------------------> DRAWER <------------------------- */}
                <Drawer />
                {questionArr.length !== 0 ? (
                    <MDBContainer center>
                        <MDBRow center>
                            <MDBCol sm="8">
                                <MDBRow>
                                    <MDBCol size="6">
                                        <p className="questions_counter">
                                            Question <span>{num + 1}</span> of {questionArr.length}
                                        </p>
                                    </MDBCol>
                                    <MDBCol size="6" style={{ textAlign: "right" }}>
                                        <p className="questions_counter">
                                            Time Left:{" "}
                                            <span>
                                                <Timer
                                                    durationInSeconds={this.state.seconds}
                                                    formatted={false}
                                                    isPaused={false}
                                                    showPauseButton={false}
                                                    showResetButton={false}
                                                    onStart={() => {
                                                        console.log("Triggered when the timer starts");
                                                    }}
                                                    onPause={(remainingDuration) => {
                                                        console.log(
                                                            "Triggered when the timer is paused",
                                                            remainingDuration
                                                        );
                                                    }}
                                                    onFinish={() => {
                                                        this.NextQuestion();
                                                    }}
                                                    onReset={(remainingDuration) => {
                                                        console.log(
                                                            "Triggered when the timer is reset",
                                                            remainingDuration
                                                        );
                                                    }}
                                                    onResume={(remainingDuration) => {
                                                        console.log(
                                                            "Triggered when the timer is resumed",
                                                            remainingDuration
                                                        );
                                                    }}
                                                />
                                            </span>
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>

                        {/* -----------------------------> RENDER QUESTION <------------------------- */}
                        <MDBRow center>
                            {/* <MDBCol sm="2"></MDBCol> */}
                            <MDBCol sm="8">
                                <Paper>
                                    <MDBContainer>
                                        <MDBRow center>
                                            <MDBCol sm="10">
                                                <p className="question_render">{questionArr[num].q}</p>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </Paper>
                            </MDBCol>
                            {/* <MDBCol sm="2"></MDBCol> */}
                        </MDBRow>
                        <br />

                        {/* -----------------------------> OPTIONS <------------------------- */}

                        {questionArr[num].options.map((val, i) => {
                            return val !== null ? (
                                <MDBRow center
                                    key={i}
                                    onClick={() =>
                                        questionArr[num].seletedValue
                                            ? null
                                            : this.selectOpiton(val, i)
                                    }
                                >
                                    {/* <MDBCol sm="4"></MDBCol> */}
                                    <MDBCol sm="8">
                                        <Paper
                                            className="options_main"
                                            style={
                                                (selectedOpt !== "" && selectedOpt === val) ||
                                                    (questionArr[num].seletedValue &&
                                                        questionArr[num].seletedValue === val)
                                                    ? { backgroundColor: "#ff9f1a" }
                                                    : { backgroundColor: "white" }
                                            }
                                        >
                                            <div className="_options_label">
                                                {i === 0 ? "A." : null}
                                                {i === 1 ? "B." : null}
                                                {i === 2 ? "C." : null}
                                                {i === 3 ? "D." : null}
                                                {i === 4 ? "E." : null}
                                                {i === 5 ? "F" : null}
                                            </div>
                                            <div className="options"> {val}</div>
                                        </Paper>
                                    </MDBCol>
                                    {/* <MDBCol sm="4"></MDBCol> */}
                                </MDBRow>
                            ) : null;
                        })}

                        <br />
                        <br />

                        {/* -----------------------------> BUTTONS <------------------------- */}
                        <MDBRow center>
                            <MDBCol sm="8">
                                <MDBRow center>
                                  
                                    <MDBCol sm="7">
                                        {num <= questionArr.length - 2 ? (
                                            <button
                                                className="next_btn"
                                                onClick={() => this.NextQuestion()}
                                                disabled={selectedOpt !== "" ? false : true}
                                            >
                                               
                                                        Next
                                            </button>
                                        ) : (
                                            <button className="next_btn">
                                                <MDBRow>
                                                    <MDBCol onClick={() => this.submitQuiz()}>
                                                        <span className="btn_txt">Submit quiz</span>
                                                    </MDBCol>
                                                </MDBRow>
                                            </button>
                                        )}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                ) : null}
                <br />
                <br />
                <br />
                <br />
            </div>
        );
    }
}
export default Playquiz;