import Math from "./Math.json";
import MathImg from "./../../../img/one.jpg";
let Perpare = [
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
  { quest: Math, image: MathImg, name: "Math" },
];

export { Perpare };
