import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Drawer from "./../../components/drawer/drawer";
import Paper from "@material-ui/core/Paper";
import LeftIcon from "./../../img/left-arrow.png";
import RightIcon from "./../../img/right-arrow.png";
import "./SubjectQuestion.css";
import MenuAppBar from "./../../components/navbar/navbar";
import { Pagination } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

let ques = [
  "1",
  "3",
  "34",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
  "5",
];

class SubjectQuestion extends Component {
  constructor() {
    super();
    this.state = {
      currentuser: {},
      modal14: false,
      page: 1,
    };
  }
  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  handleChange = (event, value) => {
    // Handle page change
    this.setState({ page: value });
    console.log(` ${value}`);
  };

  componentDidMount= ()=> {}
  render() {
    let { currentuser } = this.state;

    return (
      <div>
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_drawer">
          <Drawer data={currentuser} props={this.props} />
        </div>
        {/* -----------------------------> QUESTIONS <------------------------- */}
        {/* -----------------------------> RENDER QUESTION <------------------------- */}
        <div className="_questionMain">
          {ques.map((val, ind) => {
            if (
              (this.state.page - 1) * 10 < ind + 1 &&
              this.state.page * 10 > ind
            ) {
              console.log(
                (this.state.page - 1) * 10,
                ind,
                "=====",
                this.state.page * 10,
                ind
              );
              return (
                <div className="ques">
                  <p className="_sub_question_render">
                    <b>{ind + 1}). </b>
                    The 2 sides of a triangle are 7 m and 9 m. The two angles of
                    this triangle are 37 degree and 23 degree. Identify the
                    length of the 3rd side of the triangle from the options
                    below:{" "}
                  </p>
                  <ol type="a">
                    <li className="ques_option">Option 1</li>
                    <li className="ques_option">option 2</li>
                    <li className="ques_option">Option 3</li>
                    <li className="ques_option">Option 4</li>
                  </ol>
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Answer</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>a Optioon 1</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <hr />
                </div>
              );
            }
          })}
        </div>
        <div className="d-flex align-items-center justify-content-center ">
          <Pagination
            count={Math.ceil(ques.length / 10)}
            color="secondary"
            size="large"
            onChange={this.handleChange} // Callback function for page change
          />
        </div>
      </div>
    );
  }
}
export default SubjectQuestion;
