import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/firebase-auth";

var firebaseConfig = {
  apiKey: "AIzaSyDWSkgb15j6Sd23te-FyXVZy6DWRc-UX3Y",
  authDomain: "online-testing-service-a1c15.firebaseapp.com",
  databaseURL:
    "https://online-testing-service-a1c15-default-rtdb.firebaseio.com",
  projectId: "online-testing-service-a1c15",
  storageBucket: "online-testing-service-a1c15.appspot.com",
  messagingSenderId: "263373555053",
  appId: "1:263373555053:web:d13f7d307f6d494c9a8664",
  measurementId: "G-HQ86FNQXT4",
};
// Initialize Firebase

var Provider = new firebase.auth.GoogleAuthProvider();
const firebaseApp = firebase.initializeApp(firebaseConfig);
export { firebaseApp, Provider };
