import React from 'react';
import { MDBTable, MDBTableBody, MDBTableHead, MDBContainer } from 'mdbreact';
import Drawer from "./../../components/drawer/drawer";
import MenuAppBar from "./../../components/navbar/navbar";
// import './ChallangeResult/ChallangeResult.css'
import './ChallangeResult.css'
const BasicTable = (props) => {
    return (
        <div>
            {/* -----------------------------> DRAWER <------------------------- */}
            <div className="_menuAppBar">
                <MenuAppBar props={props} />
            </div>
            {/* -----------------------------> DRAWER <------------------------- */}
            <div className="_drawer">
                <Drawer props={props} />
            </div>
            <MDBContainer>
                <MDBTable responsive className="_table">
                    <MDBTableHead>
                        <tr>
                            <th>S.NO#</th>
                            <th>Profile</th>
                            <th>Name</th>
                            <th>Correct</th>
                            <th>Wrong</th>
                            <th>Time Spent</th>
                            <th>Status</th>
                            <th>Percentage</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        <tr>
                            <td>1</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                         
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                          
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                            <td>Cell</td>
                      
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </MDBContainer>
        </div>
    );
}

export default BasicTable;