import React, {useEffect} from 'react'
import './App.css';
import Router from './config/Router/router';
function App() {

  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
