import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { MDBBtn } from "mdbreact";
import "./Contact.css";
import Button from "@material-ui/core/Button";
import Footer from "./../../components/Footer/Footer";
import { firebaseApp } from "./../../config/Firebase";
import Drawer from "./../../components/drawer/drawer";
import Navbar from "./../../components/navbar/navbar";


class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      Email: "",
      Subject: "",
      Catagery: "",
      Discription: "",
      currentuser: {},
    };
  }

  getValue = () => {
    let flag = true;
    for (var key in this.state) {
      console.log(key);
      if (this.state[key] === "") {
        flag = false;
        this.setState({
          message: "show",
          errormessage: " ❌ Error : Please enter the " + key,
          color: "red",
        });
        setTimeout(() => {
          this.setState({
            message: "erroe",
          });
        }, 3000);
        break;
      }
    }
    if (flag === true) {
      console.log(this.state.Email);

      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.state.Email.match(mailformat)) {
        this.setState({
          message: "show",
          errormessage: "❌   Email Formate is error",
          color: "red",
        });
        setTimeout(() => {
          this.setState({
            message: "erroe",
          });
        }, 3000);
      } else {
        firebaseApp
          .firestore()
          .collection("userContact")
          .add(this.state)
          .then(() => {
            this.setState({
              message: "show",
              errormessage: "✔️  Message Send Succesfully",
              color: "green",
            });
            setTimeout(() => {
              this.setState({
                message: "erroe",
                Email: "",
                Subject: "",
                Catagery: "",
                Discription: "",
              });
            }, 3000);
          });
      }
    }
  };

  render() {
    let { currentuser } = this.state;
    return (
      <div>
        <div className="_menuAppBar">
          <Navbar props={this.props.history} />
        </div>
        <div className="_drawer">
          <Drawer data={currentuser} props={this.props} />
        </div>
        <h1 className="text">Contact Us</h1>

        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={6} lg={5}>
            <Paper className="form">
              <form action="#">
                <TextField
                  label="Email"
                  className="textfield"
                  id="outlined-margin-dense"
                  margin="dense"
                  variant="outlined"
                  name="Email"
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
                <br />
                <br />
                <TextField
                  label="Subject"
                  className="textfield"
                  id="outlined-margin-dense"
                  margin="dense"
                  variant="outlined"
                  name="Subject"
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />

                <br />
                <br />
                <div>
                  <select
                    className="browser-default custom-select"
                    name="Catagery"
                    onChange={(e) =>
                      this.setState({ [e.target.name]: e.target.value })
                    }
                  >
                    <option>Choose your option</option>
                    <option value="Developer">Developer</option>
                    <option value="Client">Client</option>
                    <option value="Businessman">Businessman</option>
                  </select>
                </div>
                <br />
                <div class="form-group green-border-focus">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea5"
                    placeholder="Write your Message ......"
                    rows="5"
                    name="Discription"
                    onChange={(e) =>
                      this.setState({ [e.target.name]: e.target.value })
                    }
                  ></textarea>
                </div>
                <Button
                  type="button"
                  style={{
                    backgroundColor: "#43423f",
                    fontWeight: "bold",
                    color: "#f9d241",
                    padding: "10px",
                    width: "100%",
                  }}
                  onClick={() => this.getValue()}
                >
                  submit
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <br />
        <div
          id="snackbar"
          className={this.state.message}
          style={{ backgroundColor: this.state.color }}
        >
          {this.state.errormessage}
        </div>
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Contact;
