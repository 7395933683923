import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import ProgressBar from "react-bootstrap/ProgressBar";
import Drawer from "./../../components/drawer/drawer";
import Paper from "@material-ui/core/Paper";
import LeftIcon from "./../../img/left-arrow.png";
import RightIcon from "./../../img/right-arrow.png";
import { Timer } from "react-countdown-clock-timer";
import "./PlayQuiz.css";
import { firebaseApp } from "./../../config/Firebase";
import moment from "moment";
import MenuAppBar from "./../../components/navbar/navbar";
import { Grid, Icon } from "@mui/material";

import EastIcon from "@mui/icons-material/East";
let quizArray = {
  name: "quiz no 1",
  detail: "This quiz convert All type of data",
  questions: [],
};

let database = firebaseApp.database().ref("/");
class Playquiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      timing: 0,
      data: props.location.state.val,
      questionArr: this.props.location.state.val,
      num: 0,
      currentOpt: false,
      selectedOpt: "",
      time: 0,
      startingDate: "",
      seconds: 40,
      Section: 0,
      questionArr: [],
      submit: false,
      Prograss: "danger",
      parcentage: 0,
    };
  }

  TimeSet(questionArr) {
    let getOpeningTime = localStorage.getItem("quizTimer");
    getOpeningTime = JSON.parse(getOpeningTime);
    if (getOpeningTime === null) {
      localStorage.setItem(
        "quizTimer",
        JSON.stringify({
          startingTime: {
            seconds: new Date().getSeconds(),
            milliseconds: new Date().getMilliseconds(),
          },
        })
      );
    }

    let getactiveQuiz = localStorage.getItem("activeQuiz");
    getactiveQuiz = JSON.parse(getactiveQuiz);
    if (getactiveQuiz === null) {
      let obj = {
        quiz: questionArr,
        timing: (50 * 40) / 60,
        num: 0,
        QuizName: this.props.location.state.val.name,
      };
      this.setState({
        questionArr: questionArr,
      });
      localStorage.setItem("activeQuiz", JSON.stringify(obj));
    } else {
      if (getactiveQuiz.QuizName !== this.props.location.state.val.name) {
        let quizData = {
          quiz: questionArr,
          QuizName: this.props.location.state.val.name,
          timing: (questionArr.length * 40) / 60,
        };
        localStorage.setItem("activeQuiz", JSON.stringify(quizData));
      } else {
        // localStorage.setItem("activeQuiz", JSON.stringify(getactiveQuiz));
        this.setState({
          questionArr: getactiveQuiz.quiz,
          propsData: getactiveQuiz.QuizName,
          num: getactiveQuiz.num,
        });
      }
    }
  }
  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  selectOpiton = async (data, index) => {
    let quiz = this.state.questionArr;
    let { num, questionArr, Section } = this.state;
    if (num <= questionArr[Section].length) {
      this.setState({ currentOpt: index, selectedOpt: data });
    } else {
      alert("already done");
    }
    if (
      quiz[Section][num - 1] !== undefined &&
      quiz[Section][num - 1].selectedValue === ""
    ) {
      quiz[Section][num - 1].selectedOption = index;
      quiz[Section][num - 1].selectedValue = data;
      this.setState({ questionArr: quiz, next: false });
    }
  };

  NextQuestion = () => {
    let { num, questionArr, selectedOpt, Section, parcentage } = this.state;
    questionArr[Section][num].seletedValue = selectedOpt;
    let getlocalData = localStorage.getItem("activeQuiz");
    getlocalData = JSON.parse(getlocalData);
    getlocalData.quiz = questionArr;
    getlocalData.num = num;
    let getOpeningTime = localStorage.getItem("quizTimer");
    getOpeningTime = JSON.parse(getOpeningTime);
    let timeSpent = {
      sec: new Date().getSeconds() - getOpeningTime.startingTime.seconds,
      millise:
        new Date().getMilliseconds() - getOpeningTime.startingTime.milliseconds,
    };
    questionArr[Section][num].timeSpent = timeSpent;
    localStorage.setItem("activeQuiz", JSON.stringify(getlocalData));
    localStorage.setItem(
      "quizTimer",
      JSON.stringify({
        startingTime: {
          seconds: new Date().getSeconds(),
          milliseconds: new Date().getMilliseconds(),
        },
      })
    );
    this.setState({ selectOpiton: "", parcentage: parcentage + 2 });
    console.log("num", num);
    if (Section !== 4 && num === 9) {
      this.setState({ num: 0, Section: Section + 1, selectOpiton: "" });
    } else if (Section !== 4 || num <= questionArr[Section].length - 2) {
      // let { num, questionArr } = this.state;
      this.setState({ num: num + 1, selectOpiton: "" });
    }
    if (Section === 4 && num === 8) {
      this.setState({ submit: true });
    }
    if (Section < 2) {
      this.setState({ Prograss: "danger" });
    }
    if (Section < 4 && Section > 1) {
      this.setState({ Prograss: "warning" });
    }
    if (Section === 4) {
      this.setState({ Prograss: "success" });
    }
    if (num < questionArr[Section].length - 1) {
      this.setState({ seconds: this.state.seconds + 0.0001 });
    }
  };

  submitQuiz = () => {
    let getlocalData = localStorage.getItem("activeQuiz");
    let attemptedQuiz = JSON.parse(getlocalData).quiz;

    console.log(getlocalData);
    var marks = 0;
    var correct = 0;
    var Wrong = 0;
    var secTion = 0;
    let ResultArray = [
      { Right: 0, Wrong: 0, Name: "English" },
      { Right: 0, Wrong: 0, Name: "Mathematics" },
      { Right: 0, Wrong: 0, Name: "General_Science" },
      { Right: 0, Wrong: 0, Name: "GK_and_Islamiat" },
      { Right: 0, Wrong: 0, Name: "Computer_and_SS" },
    ];
    for (var i = 0; i < attemptedQuiz[secTion].length; i++) {
      if (i < attemptedQuiz[secTion].length - 1) {
        if (
          attemptedQuiz[secTion][i].correct ===
          attemptedQuiz[secTion][i].seletedValue
        ) {
          correct++;
          ResultArray[secTion].Right = ResultArray[secTion].Right + 1;
          attemptedQuiz.Right = correct;
        } else {
          Wrong++;
          ResultArray[secTion].Wrong = ResultArray[secTion].Wrong + 1;
          attemptedQuiz.Wrong = Wrong;
        }
      } else {
        if (
          attemptedQuiz[secTion][i].correct ===
          attemptedQuiz[secTion][i].seletedValue
        ) {
          correct++;
          ResultArray[secTion].Right = ResultArray[secTion].Right + 1;
          attemptedQuiz.Right = correct;
        } else {
          Wrong++;
          ResultArray[secTion].Wrong = ResultArray[secTion].Wrong + 1;
          attemptedQuiz.Wrong = Wrong;
        }

        if (secTion !== 4) {
          secTion++;
          i = -1;
        }
      }
    }
    attemptedQuiz.Wrong = Wrong;
    attemptedQuiz.Right = correct;
    attemptedQuiz.ResultArray = ResultArray;

    localStorage.removeItem("activeQuiz");
    localStorage.removeItem("quizTimer");
    this.props.history.push("Result", { result: attemptedQuiz });
  };

  componentDidMount = async () => {
    let quizdata = this.props.location.state.val;
    console.log(quizdata);
    let { questionArr } = this.state;

    let Section1 = [];
    let Section2 = [];
    let Section3 = [];
    let Section4 = [];
    let Section5 = [];

    if (quizdata.Section1) {
      for (var i = 0; i < 10; i++) {
        var idx = Math.floor(Math.random() * quizdata.Section1.length);
        console.log(idx);
        let questionData = {
          question: quizdata.Section1[idx].question,
          options: [
            quizdata.Section1[idx].option1,
            quizdata.Section1[idx].option2,
            quizdata.Section1[idx].option3,
            quizdata.Section1[idx].option4,
          ],
          correct: quizdata.Section1[idx].correct,
          test: quizdata.Section1[idx].tname,
        };
        Section1.push(questionData);

        quizdata.Section1.splice(idx, 1);
      }
    }
    if (quizdata.Section2) {
      for (var i = 0; i < 10; i++) {
        var idx = Math.floor(Math.random() * quizdata.Section2.length);
        let questionData = {
          question: quizdata.Section2[idx].question,
          options: [
            quizdata.Section2[idx].option1,
            quizdata.Section2[idx].option2,
            quizdata.Section2[idx].option3,
            quizdata.Section2[idx].option4,
          ],
          correct: quizdata.Section2[idx].correct,
          test: quizdata.Section2[idx].tname,
        };
        Section2.push(questionData);
        quizdata.Section2.splice(idx, 1);
      }
    }

    if (quizdata.Section3) {
      for (var i = 0; i < 10; i++) {
        var idx = Math.floor(Math.random() * quizdata.Section3.length);
        let questionData = {
          question: quizdata.Section3[idx].question,
          options: [
            quizdata.Section3[idx].option1,
            quizdata.Section3[idx].option2,
            quizdata.Section3[idx].option3,
            quizdata.Section3[idx].option4,
          ],
          correct: quizdata.Section3[idx].correct,
          test: quizdata.Section3[idx].tname,
        };
        Section3.push(questionData);
        quizdata.Section3.splice(idx, 1);
      }
    }

    if (quizdata.Section4) {
      for (var i = 0; i < 10; i++) {
        var idx = Math.floor(Math.random() * quizdata.Section4.length);
        let questionData = {
          question: quizdata.Section4[idx].question,
          options: [
            quizdata.Section4[idx].option1,
            quizdata.Section4[idx].option2,
            quizdata.Section4[idx].option3,
            quizdata.Section4[idx].option4,
          ],
          correct: quizdata.Section4[idx].correct,
          test: quizdata.Section4[idx].tname,
        };
        Section4.push(questionData);
        quizdata.Section4.splice(idx, 1);
      }
    }
    if (quizdata.Section5) {
      for (var i = 0; i < 10; i++) {
        var idx = Math.floor(Math.random() * quizdata.Section5.length);
        let questionData = {
          question: quizdata.Section5[idx].question,
          options: [
            quizdata.Section5[idx].option1,
            quizdata.Section5[idx].option2,
            quizdata.Section5[idx].option3,
            quizdata.Section5[idx].option4,
          ],
          correct: quizdata.Section5[idx].correct,
          test: quizdata.Section5[idx].tname,
        };
        Section5.push(questionData);
        quizdata.Section5.splice(idx, 1);
      }
    }
    let questionArray = [Section1, Section2, Section3, Section4, Section5];

    console.log(questionArray);
    await this.setState({
      questionArr: questionArray,
      QuizName: this.props.location.state.val.name,
    });

    await this.TimeSet(questionArray);
  };

  render() {
    let {
      data,
      questionArr,
      num,
      currentOpt,
      selectedOpt,
      Section,
      submit,
      Prograss,
      parcentage,
    } = this.state;
    console.log(Section, num);
    const { timerActive } = this.state;

    const OPTIONS = { prefix: "seconds elapsed!", delay: 100, reset: 30 };
    console.log(questionArr);
    return (
      <div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_drawer">
          <Drawer props={this.props.history} />
        </div>
        <div className="progress_bar">
          <ProgressBar
            now={parcentage}
            label={`${parcentage}%`}
            animated
            striped={true}
            variant={Prograss}
            style={{
              height: "30px",
              borderRadius: "40px",
              width: "95%",
              margin: "auto",
            }}
          />
        </div>

        {questionArr.length !== 0 ? (
          <div className="home_card_main_play">
            <Paper className="_activeQuizView_play">
              <div className="active_q">
                Section ({questionArr[Section][num].test})
              </div>
            </Paper>
            <div>
              <MDBRow center={true}>
                <MDBCol size="6">
                  <p className="questions_counter_play">
                    Question <span>{num + 1}</span> of{" "}
                    {questionArr[Section].length}
                    <span className="Section_Name"></span>
                  </p>
                </MDBCol>
              </MDBRow>
            </div>

            <div style={{ marginBottom: "40px" }}>
              <MDBRow center={true}>
                <MDBCol lg="8" sm="10">
                  <Paper>
                    <MDBContainer>
                      <p className="question_show_play">
                        {questionArr[Section][num].question}
                      </p>
                    </MDBContainer>
                  </Paper>
                </MDBCol>
              </MDBRow>
            </div>

            <div>
              <MDBRow>
                {questionArr[Section][num].options.map((val, i) => {
                  return val !== null ? (
                    <MDBCol
                      lg="6"
                      key={i}
                      onClick={() =>
                        questionArr[Section][num].seletedValue
                          ? null
                          : this.selectOpiton(val, i)
                      }
                    >
                      <MDBContainer>
                        <Paper
                          className="options_main_play"
                          style={
                            (selectedOpt !== "" && selectedOpt === val) ||
                            (questionArr[Section][num].seletedValue &&
                              questionArr[Section][num].seletedValue === val)
                              ? { backgroundColor: "#ff9f1a" }
                              : { backgroundColor: "white" }
                          }
                        >
                          <div className="_options_label">
                            {i === 0 ? "A." : null}
                            {i === 1 ? "B." : null}
                            {i === 2 ? "C." : null}
                            {i === 3 ? "D." : null}
                            {i === 4 ? "E." : null}
                            {i === 5 ? "F" : null}
                          </div>
                          <div className="options_play"> {val}</div>
                        </Paper>
                      </MDBContainer>
                    </MDBCol>
                  ) : null;
                })}
              </MDBRow>
            </div>

            <br />
            <br />
            {/* -----------------------------> BUTTONS <------------------------- */}

            {submit ? (
              <button
                className="next_btn_play"
                onClick={() => this.NextQuestion()}
                disabled={selectedOpt !== "" ? false : true}
              >
                <span className="btn_txt_play"> Next</span>

                <EastIcon color="white" fontSize="80px" />
              </button>
            ) : (
              <button
                className="submit_button_play"
                onClick={() => this.submitQuiz()}
              >
                <span className="btn_txt_play">Submit quiz</span>
              </button>
            )}
          </div>
        ) : null}
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}
export default Playquiz;
