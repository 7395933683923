import React from "react";
import { firebaseApp } from "./../../config/Firebase";
import Paper from "@material-ui/core/Paper";
import "./AvailableQuiz.css";
import Grid from "@material-ui/core/Grid";
import MenuAppBar from "./../../components/navbar/navbar";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
} from "mdbreact";
import Drawer from "./../../components/drawer/drawer";
import MathImg from "./../../img/one.jpg";
import { Box, TextField } from "@mui/material";

let database = firebaseApp.database().ref("/");

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      papers: [],
      selected: "",
      list: [],
      modal2: false,
      selectedQuiz: {},
      category: "",
    };
  }

  
  componentDidMount() {
    let arr = [];

    firebaseApp
      .firestore()
      .collection("Tests")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());

          arr.push(doc.data());
        });
      })
      .then(() => this.setState({ list: arr }))
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    console.log(this.state.quizArr, "==========");
  }

  toggle = (nr, val) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      selectedQuiz: val,
    });
  };
  render() {
    let { list, status, selected, category } = this.state;
    let theme = "rgb(25, 100, 153)";
    // var filterArr =[]
    // if (mobileNum === null || mobileNum === "") {
    //     filterArr = this.state.Students.filter((users) => {
    //       return users && users.approved === this.state.status;
    //     });
    //   } else {
    //     filterArr = this.state.Students.filter((users) => {
    //       return users;
    //     });
    //   }
    // } else {
    //   filterArr = this.state.Students.filter((users) => {
    //     return (
    //       users && String(users.mobileNum) === String(this.state.mobileNum)
    //     );
    //   });
    // }
    const filterArr = list.filter((search) => {
      if (category === "" || selected === "") {
        return list;
      } else {
        return search.class.includes(selected) && search.category === category;
      }
    });

    console.log("================>", filterArr);

    // users && String(users.mobileNum) === String(this.state.mobileNum)
    return (
      <div>
        {/* -----------------------------> NAVBAR <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>
        <div className="_drawer">
          <Drawer props={this.props} />
        </div>

        {/* -----------------------------> DRAWER <------------------------- */}
        <MDBContainer>
          <div className="_inner_view">
            <Grid container spacing={2} justify="center">
              <Grid lg={12} sm={12}>
                <Box
                  sx={{
                    // width: 500,
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Search Quiz Name"
                    id="fullWidth"
                  />
                </Box>
              </Grid>
              {filterArr.length !== 0 ? (
                filterArr.map((val, i) => (
                  <MDBCol
                    xl="3"
                    lg="4"
                    md="6"
                    sm="6"
                    xs="6"
                    className="mb-lg-0 mb-4"
                  >
                    <MDBCard testimonial>
                      {/* <MDBCardUp color="info" /> */}
                      {/* <MDBAvatar className="mx-auto white"> */}
                      <div className="_card_header">
                        <img className="_subjectImage" src={MathImg} />
                      </div>

                      {/* </MDBAvatar> */}
                      <MDBCardBody>
                        <div className="_card_title_a">
                          <h6 className="font-weight-bold">{val.name}</h6>
                          <button
                            className="_position_title_a"
                            onClick={() =>
                              this.props.history.push("Playquiz", { val })
                            }
                          >
                            Start
                          </button>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))
              ) : (
                <Grid item xs={10} lg={10}>
                  <Paper className="quiz_list">
                    <span className="quiz_list_text">No quiz available!</span>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </div>
        </MDBContainer>
      </div>
    );
  }
}
