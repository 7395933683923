import React, { useEffect, useState } from "react";
import "./Nomatch.css";
import { firebaseApp } from "./../../config/Firebase";
import RandomQuiz from "./../PlaRandomQuiz/PlaRandomQuiz";
import {
  BrowserRouter as Router,
  useLocation,
  useHistory,
} from "react-router-dom";
let database = firebaseApp.database().ref("/");
function Nomatch() {
  let location = useLocation();
  let getparams = useHistory();
  let [quiz, setQuiz] = useState(false);
  let [loading, setLoaing] = useState(false);

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("user login");
      } else {
        getparams.push("signup");
      }
    });

    setLoaing(true);
    database.child("DailyBaseQuizList/").on("child_added", (res) => {
      let data = res.val();
      data.key = res.key;
      let selectedId = location.pathname.substring(1);
      if (data.quizId === selectedId) {
        setQuiz(data);
        setLoaing(false);
      } else {
        setLoaing(false);
      }
    });
  }, []);

  return (
    <div>
      {loading ? (
        <div className="_loading_container">
          <div className="spinner-border text-info" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          {quiz === false ? (
            <div class="message-box">
              <h1>404</h1>
              <p>Page not found</p>
              <div class="buttons-con">
                <div class="action-link-wrap">
                  <a href="/" class="link-button">
                    Go Back
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <RandomQuiz
              quiz={quiz.quiz}
              quizUid={quiz.key}
              props={getparams}
              quizKey={quiz.quizId}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Nomatch;
