import React from "react";
import { firebaseApp } from "../../config/Firebase";
import Grid from "@material-ui/core/Grid";
import Drawer from "../../components/drawer/drawer";
import MenuAppBar from "../../components/navbar/navbar";
import { MDBIcon, MDBBtn ,MDBContainer} from "mdbreact";
import ReadMoreAndLess from "react-read-more-less";

let database = firebaseApp.database().ref("/");
export default class Blog extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    let posts = [];
    database.child("BlogPost/").on("child_added", async (res) => {
      let data = res.val();
      data.quizId = res.key;
      posts.push(data);
      this.setState({ posts });
    });
  }

  render() {
    let { posts } = this.state;
    return (
      <div>
        {/* -----------------------------> NAVBAR <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_drawer">
          <Drawer props={this.props} />
        </div>
        <MDBContainer>

        <div className="_inner_view">
          <Grid container spacing={2} justify="center">
            <Grid item xs={11} lg={10}>
              <span className="_sub_heading">Latest Educational News:</span>
              <div className="_post_container">
                {posts.length !== 0 ? (
                  posts.map((val, i) => {
                    return (
                      <div
                        lg="3"
                        md="3"
                        className="mb-lg-0 mb-4"
                        className="_post_view"
                      >
                        <img
                          className="img-fluid"
                          src={val.img}
                          alt=""
                          style={{ height: 120, width: "100%" }}
                        />
                        <div style={{ padding: 8, fontSize: 12 }}>
                          <a href="#!" className="deep-orange-text">
                            <div className="font-weight-bold">
                              <MDBIcon icon="graduation-cap" className="pr-2" />
                              Education
                            </div>
                          </a>
                          <strong style={{ fontSize: 12, fontWeight: "bold" }}>
                            {val.title}
                          </strong>
                          <p>
                            By{" "}
                            <a href="#!" className="font-weight-bold">
                              Billy Forester
                            </a>
                            , 13/07/2018
                          </p>
                          <div style={{ fontSize: 10, textAlign: "justify" }}>
                            <ReadMoreAndLess
                              ref={val.des}
                              className="read-more-content"
                              charLimit={150}
                              readMoreText={
                                <MDBBtn
                                  color="deep-orange"
                                  rounded
                                  size="md"
                                  style={{
                                    padding: 5,
                                    fontSize: 10,
                                    display: "block",
                                  }}
                                >
                                  Read more
                                </MDBBtn>
                              }
                              readLessText={
                                <MDBBtn
                                  color="deep-orange"
                                  rounded
                                  size="md"
                                  style={{
                                    padding: 5,
                                    fontSize: 10,
                                    display: "block",
                                  }}
                                >
                                  Read less
                                </MDBBtn>
                              }
                            >
                              {val.des}
                            </ReadMoreAndLess>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1>No any current news available</h1>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        </MDBContainer>
      </div>
    );
  }
}
