import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import Paper from "@material-ui/core/Paper";
import "./PlaRandomQuiz.css";
import { firebaseApp } from "./../../config/Firebase";
import Drawer from "./../../components/drawer/drawer";
import MenuAppBar from "./../../components/navbar/navbar";

let database = firebaseApp.database().ref("/");
class PlaRandomQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      timing: 0,
      data: props.quiz,
      questionArr: [],
      i: 0,
      currentOpt: false,
      selectedOpt: "",
      time: 0,
      startingDate: "",
      seconds: 40,
      curentuser: {},
      isSubmited: false,
    };
  }
  componentDidMount() {
    let questions = this.props.quiz;
    let currentQuiz = localStorage.getItem("activeQuiz");
    currentQuiz = JSON.parse(currentQuiz);
    if (currentQuiz === null) {
      let questionArr = [];
      for (var i = 0; i < questions.length; i++) {
        let obj = {
          q: questions[i].question,
          options: [
            questions[i].option1,
            questions[i].option2,
            questions[i].option3,
            questions[i].option4,
            questions[i].option5,
          ],
          correct: questions[i].answer,
        };
        questionArr.push(obj);
        this.setState({ questionArr: questionArr });
      }
    } else {
      this.setState({ questionArr: currentQuiz });
    }

    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        let obj = {
          uid: user.uid,
          attemptedBy: user.displayName,
          photoURL: user.photoURL,
        };
        this.setState({ curentuser: obj });

        database
          .child(
            "DailyBaseQuizList/" + this.props.quizUid + "/" + "AttemptedList/"
          )
          .on("child_added", (check) => {
            let value = check.val();
            if (value.userid === user.uid) {
              console.log("you already submit quiz");
              this.setState({ isSubmited: true });
            }
          });
      } else {
        console.log("No user is signed in.");
      }
    });
  }
  toggle = (nr) => () => {
    let modaliber = "modal" + nr;
    this.setState({
      [modaliber]: !this.state[modaliber],
    });
  };

  selectOpiton = async (data, index) => {
    let quiz = this.state.questionArr;
    quiz[index].selectedOption = index;
    quiz[index].selectedValue = data;
    localStorage.setItem("activeQuiz", JSON.stringify(quiz));
    this.setState({ questionArr: quiz });
  };

  submitQuiz = () => {
    let getlocalData = localStorage.getItem("activeQuiz");
    getlocalData = JSON.parse(getlocalData);
    var correct = 0;
    var wrong = 0;
    var misssed = 0;
    let attemptedQuiz = getlocalData;
    let { curentuser } = this.state;
    for (var i = 0; i < attemptedQuiz.length; i++) {
      if (attemptedQuiz[i].correct === attemptedQuiz[i].seletedValue) {
        correct++;
      }
      if (attemptedQuiz[i].selectedValue) {
        wrong++;
      }
      if (attemptedQuiz[i].selectedValue === undefined) {
        misssed++;
      }
    }
    let resultData = {
      correct: correct,
      wrong: wrong,
      misssed: misssed,
      attemptedBy: curentuser,
      attemptedBy: curentuser.attemptedBy,
      profile: curentuser.photoURL,
      userid: curentuser.uid,
      attemptedQuiz: getlocalData,
    };

    let confirm = window.confirm(
      "Are you want to submit quiz? \n Click ok to see your result"
    );
    if (confirm) {
      database
        .child(
          "DailyBaseQuizList/" + this.props.quizUid + "/" + "AttemptedList"
        )
        .push(resultData);
      localStorage.removeItem("activeQuiz");
      this.props.props.push("AttemptedAns", { quizKey: this.props.quizKey });
    }
  };
  render() {
    let { questionArr, isSubmited } = this.state;

    return (
      <div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.props} />
        </div>
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_drawer">
          <Drawer props={this.props} />
        </div>
        <MDBContainer>
          {!isSubmited ? (
            <>
              {questionArr.length !== 0 ? (
                <>
                  {questionArr.length !== 0 ? (
                    <div className="home_card_main">
                      {/* -----------------------------> QUESTIONS <------------------------- */}
                      {questionArr.map((qData, i) => {
                        return (
                          <div>
                            <MDBRow>
                              <MDBCol sm="8">
                                <MDBRow>
                                  <MDBCol size="12">
                                    <p
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flex: 1,
                                        width: "100%",
                                        fontSize: 14,
                                        marginTop: 15,
                                        marginBottom: -5,
                                      }}
                                    >
                                      <span>
                                        <span>Q.{i + 1}).</span>
                                      </span>
                                      <span>{qData.q}</span>
                                    </p>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>

                            {/* -----------------------------> RENDER QUESTION <------------------------- */}
                            <MDBRow>
                              <MDBCol sm="8">
                                <Paper>
                                  <MDBContainer>
                                    <MDBRow>
                                      <MDBCol sm="10">
                                        {/* <p className="question_show">{questionArr[0].question}</p> */}
                                      </MDBCol>
                                    </MDBRow>
                                  </MDBContainer>
                                </Paper>
                              </MDBCol>
                              <MDBCol sm="2"></MDBCol>
                            </MDBRow>
                            <br />

                            {/* -----------------------------> OPTIONS <------------------------- */}

                            {questionArr[i].options.map((val, j) => {
                              return val !== null && val !== undefined ? (
                                <MDBRow
                                  key={j}
                                  onClick={() =>
                                    questionArr[i].seletedValue
                                      ? null
                                      : this.selectOpiton(val, i)
                                  }
                                >
                                  <MDBCol sm="8">
                                    <Paper
                                      className="options_main"
                                      style={
                                        questionArr[i].selectedValue &&
                                        questionArr[i].selectedValue === val
                                          ? {
                                              backgroundColor:
                                                "rgb(105 186 244)",
                                            }
                                          : { backgroundColor: "white" }
                                      }
                                    >
                                      <div className="_options_label">
                                        {j === 0 ? "A." : null}
                                        {j === 1 ? "B." : null}
                                        {j === 2 ? "C." : null}
                                        {j === 3 ? "D." : null}
                                        {j === 4 ? "E." : null}
                                        {j === 5 ? "F." : null}
                                      </div>
                                      <div className="options"> {val}</div>
                                    </Paper>
                                  </MDBCol>
                                </MDBRow>
                              ) : null;
                            })}
                          </div>
                        );
                      })}

                      <br />
                      <br />

                      {/* -----------------------------> BUTTONS <------------------------- */}
                      <MDBRow>
                        <MDBCol sm="8">
                          <MDBRow>
                            <p
                              style={{
                                textAlign: "right",
                                width: "100%",
                                marginRight: 10,
                              }}
                            >
                              <button className="next_btn">
                                <MDBRow>
                                  <MDBCol onClick={() => this.submitQuiz()}>
                                    <span className="btn_txt">Submit</span>
                                  </MDBCol>
                                </MDBRow>
                              </button>
                            </p>
                          </MDBRow>
                        </MDBCol>
                        <MDBCol sm="2"></MDBCol>
                      </MDBRow>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          ) : (
            <div className="_alrady_submited">
              <p>You Alrady Submited quiz</p>
              <p>Quiz key:{this.props.quizKey}</p>

              <MDBBtn
                color="#f27c66"
                style={{ backgroundColor: "#f27c66" }}
                className="_start_button"
                size="sm"
                onClick={() => this.props.props.push("AttemptedAns")}
              >
                View Score
              </MDBBtn>
            </div>
          )}
          <br />
          <br />
          <br />
          <br />
        </MDBContainer>
      </div>
    );
  }
}
export default PlaRandomQuiz;
