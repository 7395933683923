import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdbreact";
import Team from "./../../img/hishmatrai.jpg";
// import Cover from './../../Images/ourteam.jpg'
export default class Cards extends React.Component {
  render() {
    return (
      <MDBCol
        md="4"
        style={{
          maxWidth: "18rem",
          backgroundColor: "#0a0a0c",
          borderRadius: "5%",
          marginLeft: "5%",
          marginTop: 10,
        }}
      >
        {/* <MDBCard
                    style={{ borderStyle: 'solid', borderColor: '#f0cb3f', borderWidth: 2, backgroundColor: '#0a0a0c' }}
                > */}
        <MDBCardImage
          top
          src={Team}
          style={{
            borderRadius: "100%",
            width: 120,
            height: 120,
            textAlign: "center",
            top: 10,
            margin: "auto",
            borderStyle: "solid",
            borderColor: "#fff",
            borderWidth: 5,
          }}
          overlay="#f0cb3f"
          hover
          waves
          alt="MDBCard image cap"
        />
        <MDBCardBody>
          <MDBCardTitle style={{ color: "#f0cb3f", textAlign: "center" }}>
            {this.props.name}
          </MDBCardTitle>
          <hr className="yellow" />
          <MDBCardText style={{ color: "#f0cb3f", textAlign: "justify" }}>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </MDBCardText>

          {/* <div className="text-center text-md-right" style={{margin: 'auto'}}> */}
          <ul className="list-unstyled list-inline" style={{ margin: "auto" }}>
            <li
              className="list-inline-item"
              style={{ backgroundColor: "#f0cb3f" }}
            >
              <a
                className="btn-floating btn-sm rgba-white-slight mx-1"
                href="#"
                style={{ color: "#0a0a0c" }}
              >
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li
              className="list-inline-item"
              style={{ backgroundColor: "#f0cb3f" }}
            >
              <a
                className="btn-floating btn-sm rgba-white-slight mx-1"
                href="#"
                style={{ color: "#0a0a0c" }}
              >
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li
              className="list-inline-item"
              style={{ backgroundColor: "#f0cb3f" }}
            >
              <a
                className="btn-floating btn-sm rgba-white-slight mx-1"
                href="#"
                style={{ color: "#0a0a0c" }}
              >
                <i className="fab fa-google-plus" />
              </a>
            </li>
            <li
              className="list-inline-item"
              style={{ backgroundColor: "#f0cb3f" }}
            >
              <a
                className="btn-floating btn-sm rgba-white-slight mx-1"
                href="#"
                style={{ color: "#0a0a0c" }}
              >
                <i className="fab fa-linkedin-in" />
              </a>
            </li>
          </ul>
          {/* </div> */}
        </MDBCardBody>
        {/* </MDBCard> */}
      </MDBCol>
    );
  }
}
