import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import {
  Login,
  Signup,
  Home,
  Questions,
  Playquiz,
  Result,
  Profile,
  AvailableQuiz,
  Blog,
  Scholorship,
  QuizList,
  Nomatch,
  AttemptedAns,
  PlaRandomQuiz,
  ViewResult,
  ShareWith,
  ChallangeQuiz,
  ChallangeResult,
  Contact,
  About,
  SubjectQuestion,
} from "./../../pages";
export default class BasicRouter extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/SubjectQuestion/:id" component={SubjectQuestion} />
          {/* <Route exact path="/Signup" component={Signup} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/Questions" component={Questions} />*/}
          <Route exact path="/Playquiz" component={Playquiz} />
          <Route exact path="/Result" component={Result} />
          <Route exact path="/Profile" component={Profile} />
          <Route exact path="/availablequiz" component={AvailableQuiz} />
          <Route exact path="/quizlist" component={QuizList} />
          <Route exact path="/AttemptedAns" component={AttemptedAns} />
          <Route exact path="/PlaRandomQuiz" component={PlaRandomQuiz} />
          <Route exact path="/viewresult" component={ViewResult} />
          <Route exact path="/ShareWith" component={ShareWith} />
          <Route exact path="/ChallangeQuiz" component={ChallangeQuiz} />
          <Route exact path="/ChallangeResult" component={ChallangeResult} />
          <Route exact path="/Nomatch" component={Nomatch} />
          <Route exact path="/Contact" component={Contact} />
          <Route exact path="/services" component={Contact} />
          <Route exact path="/About" component={About} />

          <Route path="*">
            <Nomatch />
          </Route>
        </Switch>
      </Router>
    );
  }
}
