import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Drawer from "./../../components/drawer/drawer";
import Paper from "@material-ui/core/Paper";
import "./ViewResult.css";
import { firebaseApp } from "./../../config/Firebase";
import MenuAppBar from "./../../components/navbar/navbar";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBTable,
  MDBIcon,
} from "mdbreact";
import Grid from "@material-ui/core/Grid";
import logo from "./../../img/logo icon 2-01.png";
let database = firebaseApp.database().ref("/");
class ViewResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      timing: 0,
      data: props.quiz,
      questionArr: [],
      i: 0,
      currentOpt: false,
      selectedOpt: "",
      time: 0,
      startingDate: "",
      seconds: 40,
      curentuser: {},
      quizKey: "",
      totalQuiz: [],
      showQuiz: true,
      RenderQuiz: [],
    };
  }
  componentDidMount() {
    let getProps = this.props.location.state.data;
    let totalQuiz = [];
    database
      .child("DailyBaseQuizList/" + getProps.key + "/" + "AttemptedList/")
      .on("child_added", (res) => {
        let data = res.val();
        data.key = res.key;
        totalQuiz.push(data);
        this.setState({ totalQuiz: totalQuiz });
      });

    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        let obj = {
          uid: user.uid,
          attemptedBy: user.displayName,
          photoURL: user.photoURL,
        };
        this.setState({ curentuser: obj });
      } else {
        console.log("No user is signed in.");
      }
    });
  }
  toggle = (nr) => () => {
    let modaliber = "modal" + nr;
    this.setState({
      [modaliber]: !this.state[modaliber],
    });
  };

  ShowAnswer = (d) => {
    this.setState({ RenderQuiz: d, showQuiz: false });
  };

  render() {
    let { totalQuiz, curentuser, showQuiz, RenderQuiz } = this.state;

    let getProps = this.props.location.state.data;
    return (
      <div className="_result_container">
        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_menuAppBar">
          <MenuAppBar props={this.props.history} />
        </div>

        {/* -----------------------------> DRAWER <------------------------- */}
        <div className="_drawer">
          <Drawer props={this.props.history} />
        </div>
        <br />
        <br />
        <br />
        <br />
        <MDBContainer className="_view_result_container">
          {showQuiz ? (
            <MDBCard narrow>
              <MDBCardHeader
                className="view view-cascade gradient-card-header peach-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3"
                style={{ marginTop: -30, borderRadius: 10 }}
              >
                <div>
                  <img src={logo} style={{ height: 40, width: 40 }} />
                </div>
                <h3
                  style={{
                    paddingTop: 10,
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                  }}
                  className="_meritlist_heading"
                >
                  PST/JST QUIZ MARIT LIST
                </h3>
              </MDBCardHeader>
              <MDBCardBody cascade>
                <MDBTable btn fixed>
                  {/* available quiz */}
                  {totalQuiz.length !== 0 ? (
                    totalQuiz.map((val, i) => {
                      return (
                        <Grid item xs={12} lg={12}>
                          <Paper
                            className="quiz_list"
                            style={{
                              borderLeft: `8px solid #f27c66`,
                              width: "100%",
                              marginBottom: 10,
                              backgroundColor:
                                curentuser.uid === val.userid
                                  ? "#d5ffd5"
                                  : "white",
                            }}
                          >
                            <div className="_quiz_logo">
                              <img
                                src={val.profile}
                                style={{
                                  height: 40,
                                  width: 40,
                                  borderRadius: 20,
                                }}
                              />
                            </div>

                            <span className="_td">{val.attemptedBy}</span>
                            <span className="_td">{val.misssed}</span>
                            <span className="_td">{val.wrong}</span>
                            <span className="_td">{val.correct}</span>

                            <span className="_td">
                              {(
                                (val.correct / val.attemptedQuiz.length) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                            <span className="_td">
                              {val.correct / val.attemptedQuiz.length <
                              getProps.passingScore ? (
                                <span style={{ color: "red" }}>Failed</span>
                              ) : (
                                <span style={{ color: "green" }}>Passed</span>
                              )}{" "}
                            </span>

                            {curentuser.uid === val.userid ? (
                              <MDBIcon
                                icon="external-link-alt"
                                onClick={() =>
                                  this.ShowAnswer(val.attemptedQuiz)
                                }
                              />
                            ) : (
                              <span></span>
                            )}
                          </Paper>
                        </Grid>
                      );
                    })
                  ) : (
                    <Grid item xs={10} lg={10}>
                      <Paper
                        className="quiz_list"
                        style={{ borderLeft: `8px solid #f27c66` }}
                      >
                        <span>No quiz available!</span>
                      </Paper>
                    </Grid>
                  )}
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
          ) : null}
        </MDBContainer>
        {/* export default TablePage; */}
        {/* <<<<<<<<<<<<<END RESULT SHEET>>>>>>>>>>> */}
        {RenderQuiz.length !== 0 ? (
          <>
            {RenderQuiz.length !== 0 ? (
              <div className="home_card_main">
                {RenderQuiz.map((qData, i) => {
                  return (
                    <div>
                      <MDBRow>
                        <MDBCol sm="8">
                          <MDBRow>
                            <MDBCol size="12">
                              <p
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  flex: 1,
                                  width: "100%",
                                  fontSize: 14,
                                  marginTop: 15,
                                  marginBottom: -5,
                                }}
                              >
                                <span>
                                  <span>Q.{i + 1}).</span>
                                </span>
                                <span>{qData.q}</span>
                              </p>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol sm="8">
                          <Paper>
                            <MDBContainer>
                              <MDBRow>
                                <MDBCol sm="10"></MDBCol>
                              </MDBRow>
                            </MDBContainer>
                          </Paper>
                        </MDBCol>
                        <MDBCol sm="2"></MDBCol>
                      </MDBRow>
                      {RenderQuiz[i].selectedOption === undefined ? (
                        <div className="_missed">
                          Misssed{" "}
                          <MDBIcon
                            far
                            icon="check-circle"
                            style={{ color: "grey", fontSize: 12 }}
                          />{" "}
                        </div>
                      ) : RenderQuiz[i].correct ===
                        RenderQuiz[i].selectedValue ? (
                        <div className="_correct">
                          Correct{" "}
                          <MDBIcon
                            far
                            icon="check-circle"
                            style={{ color: "green", fontSize: 12 }}
                          />
                        </div>
                      ) : (
                        <div className="_wrong">
                          wrong
                          <MDBIcon
                            far
                            icon="check-circle"
                            style={{ color: "rgb(217, 88, 88", fontSize: 12 }}
                          />
                        </div>
                      )}

                      <br />
                      {RenderQuiz[i].selectedOption !== null &&
                      RenderQuiz[i].selectedOption !== undefined
                        ? RenderQuiz[i].options.map((val, j) => {
                            return val !== null ? (
                              <MDBRow key={j}>
                                <MDBCol sm="8">
                                  <Paper
                                    className="options_main"
                                    style={
                                      RenderQuiz[i].selectedOption === j
                                        ? RenderQuiz[i].selectedValue ===
                                          RenderQuiz[i].correct
                                          ? {
                                              backgroundColor:
                                                "rgb(145, 215, 109)",
                                            }
                                          : {
                                              backgroundColor: "#fabdbd",
                                              color: "#d95858",
                                            }
                                        : RenderQuiz[i].correct === val
                                        ? { backgroundColor: "#91d76d" }
                                        : {
                                            backgroundColor: "white",
                                            opacity: 0.7,
                                          }
                                    }
                                  >
                                    <div className="_options_label">
                                      {j === 0 ? "A." : null}
                                      {j === 1 ? "B." : null}
                                      {j === 2 ? "C." : null}
                                      {j === 3 ? "D." : null}
                                      {j === 4 ? "E." : null}
                                      {j === 5 ? "F." : null}
                                    </div>
                                    <div className="options"> {val}</div>
                                  </Paper>
                                </MDBCol>
                              </MDBRow>
                            ) : null;
                          })
                        : RenderQuiz[i].options.map((val, j) => {
                            return val !== null ? (
                              <MDBRow key={j}>
                                <MDBCol sm="8">
                                  <Paper
                                    className="options_main"
                                    style={
                                      RenderQuiz[i].selectedOpt === null ||
                                      (RenderQuiz[i].selectedOpt == undefined &&
                                        RenderQuiz[i].correct === val)
                                        ? {
                                            backgroundColor: "#cbffb0",
                                          }
                                        : {
                                            backgroundColor: "white",
                                            opacity: 0.7,
                                          }
                                    }
                                  >
                                    <div className="_options_label">
                                      {j === 0 ? "A." : null}
                                      {j === 1 ? "B." : null}
                                      {j === 2 ? "C." : null}
                                      {j === 3 ? "D." : null}
                                      {j === 4 ? "E." : null}
                                      {j === 5 ? "F." : null}
                                    </div>
                                    <div className="options"> {val}</div>
                                  </Paper>
                                </MDBCol>
                              </MDBRow>
                            ) : null;
                          })}
                    </div>
                  );
                })}

                <br />
                <br />
                <MDBRow>
                  <MDBCol sm="8">
                    <MDBRow>
                      <p
                        style={{
                          textAlign: "right",
                          width: "100%",
                          marginRight: 10,
                        }}
                      >
                        <button
                          className="next_btn"
                          onClick={() =>
                            this.props.history.push("attemptedAns")
                          }
                          style={{ backgroundColor: "#f27c66" }}
                        >
                          <MDBRow>
                            <MDBCol>
                              <span className="btn_txt">Go Back</span>
                            </MDBCol>
                          </MDBRow>
                        </button>
                      </p>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol sm="2"></MDBCol>
                </MDBRow>
              </div>
            ) : null}
          </>
        ) : null}
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}
export default ViewResult;
