// import React, { useEffect } from "react";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
// import Typography from "@material-ui/core/Typography";
// import MenuItem from "@material-ui/core/MenuItem";
// import Menu from "@material-ui/core/Menu";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import "./navbar.css";
// import logo from "./../../img/nts quiz logo22-01.png";
// import { firebaseApp, Provider } from "./../../config/Firebase";
// import { makeStyles } from "@mui/styles";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { Fade } from "@mui/material";

// let database = firebaseApp.database().ref("/");

// var theme = createTheme();
// console.log(theme.spacing(2));
// // console.log(Fade(theme.palette.common.white, 0.15));
// const useStyles = makeStyles({
//   grow: {
//     flexGrow: 0.2,
//     // border:"2px solid red",
//     flex:1
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     display: "none",
//     [theme.breakpoints.up("sm")]: {
//       display: "block",
//     },
//     color: "#f27c66",
//     fontWeight: "bold",
//   },
//   search: {
//     position: "relative",
//     borderRadius: theme.shape.borderRadius,
//     // backgroundColor: Fade(theme.palette.common.white, 0.15),
//       // "&:hover": {
//       //   // backgroundColor: Fade(theme.palette.common.white, 0.25),
//       // },
//       marginRight: theme.spacing(2),
//       marginLeft: 0,
//       width: "100%",
//       [theme.breakpoints.up("sm")]: {
//         marginLeft: theme.spacing(3),
//         width: "auto",
//       },
//   },
//   searchIcon: {
//     padding: theme.spacing(0, 2),
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   inputRoot: {
//     color: "inherit",
//   },
//   inputInput: {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
//   sectionDesktop: {
//     display: "none",

//     [theme.breakpoints.up("md")]: {
//       display: "flex",
//     },
//   },
//   sectionMobile: {
//     display: "flex",
//     [theme.breakpoints.up("md")]: {
//       display: "none",
//     },
//   },
// });

// export default function PrimarySearchAppBar(props) {
//   const classes = useStyles();
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
//   const [currentuser, setcurrentUser] = React.useState(false);

//   useEffect(() => {
//     firebaseApp.auth().onAuthStateChanged((user) => {
//       if (user) {
//         setcurrentUser(user);
//         localStorage.setItem("activeuser", JSON.stringify(user));
//       } else {
//         console.log("No user is signed in.");
//       }
//     });
//   }, []);
//   const isMenuOpen = Boolean(anchorEl);
//   const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

//   const handleProfileMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMobileMenuClose = () => {
//     setMobileMoreAnchorEl(null);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     handleMobileMenuClose();
//   };

//   const handleMobileMenuOpen = (event) => {
//     setMobileMoreAnchorEl(event.currentTarget);
//   };

//   const menuId = "primary-search-account-menu";
//   const renderMenu = (
//     <Menu
//       anchorEl={anchorEl}
//       anchorOrigin={{ vertical: "top", horizontal: "right" }}
//       id={menuId}
//       keepMounted
//       transformOrigin={{ vertical: "top", horizontal: "right" }}
//       open={isMenuOpen}
//       onClose={handleMenuClose}
//     >
//       <MenuItem onClick={handleMenuClose}>LOGOUT</MenuItem>
//     </Menu>
//   );

//   const mobileMenuId = "primary-search-account-menu-mobile";
//   const signwithGoogle = () => {
//     firebaseApp
//       .auth()
//       .signInWithPopup(Provider)
//       .then((result) => {
//         /** @type {firebase.auth.OAuthCredential} */
//         var credential = result.credential;
//         // This gives you a Google Access Token. You can use it to access the Google API.
//         var token = credential.accessToken;
//         // The signed-in user info.
//         var user = result.user;
//         database.child("users/" + user.uid + "/").set({
//           user: user.displayName,
//           photo: user.photoURL,
//           email: user.email,
//           uid: user.uid,
//           token: token,
//         });
//         // ...
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
//   return (
//     <ThemeProvider theme={theme}>
//       <div className={classes.grow}>
//         <AppBar className="_App_Bar" position="fixed">
//           <Toolbar>
//             <Typography className={classes.title} variant="h6" noWrap>
//               {/* <img src={logo} style={{ height: 40 }} /> */}
//               Online Quiz Service
//             </Typography>
//             <div className={classes.grow} />
//             <div className={classes.sectionDesktop}>
//               <a className="_pages" onClick={() => props.props.push("/")}>
//                 Home
//               </a>
//               <a
//                 className="_pages"
//                 onClick={() => props.props.push("availablequiz")}
//               >
//                 PST/JST Prep
//               </a>
//               <a
//                 className="_pages"
//                 onClick={() => props.props.push("AttemptedAns")}
//               >
//                 Result
//               </a>

//               <a className="_pages" onClick={() => props.props.push("Contact")}>
//                 Contact Us
//               </a>
//               <a className="_pages" onClick={() => props.props.push("About")}>
//                 About Us
//               </a>
//               {!currentuser ? (
//                 <a className="_pages" onClick={() => props.props.push("login")}>
//                   Login
//                 </a>
//               ) : (
//                 <img
//                   src={currentuser.photoURL}
//                   style={{
//                     height: 30,
//                     width: 30,
//                     borderRadius: 15,
//                     verticalAlign: "middle",
//                   }}
//                   onClick={() => props.props.push("Profile")}
//                 />
//               )}
//             </div>
//             <div className={classes.sectionMobile}>
//               <IconButton
//                 aria-label="show more"
//                 aria-controls={mobileMenuId}
//                 aria-haspopup="true"
//                 onClick={handleMobileMenuOpen}
//                 color="inherit"
//               >
//                 <MoreVertIcon color="primary" />
//               </IconButton>
//             </div>
//           </Toolbar>
//         </AppBar>

//         {renderMenu}
//       </div>
//     </ThemeProvider>
//   );
// }

// Navbar.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";
import logo from "./../../img/quiz-logo-png-transparent.png";

const Navbar = (props) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-black  fixed-top _App_Bar">
      <div className="container">
        <a className="navbar-brand" href="/">
          {/* <img src={logo} className="_logo" width={"20%"} height={"30%"} /> */}

          <p style={{ color: "rgb(200, 199, 202)", fontWeight: "bold" }}>
            Online Quiz Service
          </p>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link _pages" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link _pages" href="/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link _pages"
                href="/services"
              >
                Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link _pages" href="/contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
